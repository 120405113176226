import React, { createContext, useState } from "react";

const ModalCtx2 = createContext();

export function ModalCtxProvider2(props) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  const context = {
    isOpen: isOpen,
    toggleIsOpen: toggleIsOpen,
  };
  return (
    <ModalCtx2.Provider value={context}>{props.children}</ModalCtx2.Provider>
  );
}

export default ModalCtx2;
