import { Link, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Logo from './assets/HM logo 02b.png'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HealthnessDash from './components/main/healthness/dash';
import Dashboard from './components/main/dashboard/dashboard';
import Nav from './Nav';
import Footer from './footer';
import React, { useEffect, useState } from 'react';
import HeartRate from './components/main/healthness/heartrate';
import Scan from './components/main/scan';
import Cleansers from './components/main/skin_health/cleansers';
import SkinDash from './components/main/skin_health/skin_health_dash';
import Moisturizers from './components/main/skin_health/moisturizers';
import BreathingRate from './components/main/healthness/breathingrate';
import Spo2 from './components/main/healthness/spo2';
import BloodPressure from './components/main/healthness/bloodpressure';
import Cholesterol from './components/main/healthness/cholesterol';
import BloodSugar from './components/main/healthness/bloodsugar';
import HealthTips from './components/main/healthness/healthtips';
import Sunscreen from './components/main/skin_health/sunscreen';
import Exfoliants from './components/main/skin_health/exfoliants';
import AntiAging from './components/main/skin_health/Antiaging';
import Brightening from './components/main/skin_health/Brightening';
import AcneFighting from './components/main/skin_health/Acnefighting';
import SoothingSensitiveSkin from './components/main/skin_health/soothingsensitiveskin';
import DietDash from './components/main/wellness/dietdash';
import VitaminDash from './components/main/wellness/vitamindash';
import VitaminD from './components/main/wellness/vitamind';
import VitaminC from './components/main/wellness/vitaminc';
import VitaminBDash from './components/main/wellness/vitaminbdash';
import MineralsDash from './components/main/wellness/mineralsdash';
import NutrientsDash from './components/main/wellness/nutrientsdash';
import VitaminE from './components/main/wellness/vitamine';
import VitaminB3 from './components/main/wellness/vitamineb3';
import VitaminB9 from './components/main/wellness/vitamineb9';
import VitaminBComplex from './components/main/wellness/vitaminbcomp';
import MineralMg from './components/main/wellness/MineralMg';
import MineralCa from './components/main/wellness/MineralCa';
import MineralFe from './components/main/wellness/MineralFe';
import MineralPh from './components/main/wellness/MineralPh';
import NutriProbio from './components/main/wellness/NutriProbio';
import NutriLTh from './components/main/wellness/NutriLTh';
import NutriPsa from './components/main/wellness/NutriPsa';
import NutriOm3 from './components/main/wellness/NutriOm3';
import NutriRedRice from './components/main/wellness/NutriRedRice';
import FitStress from './components/main/wellness/FitStress';
import FitFatigue from './components/main/wellness/FitFatigue';
import FitBone from './components/main/wellness/FitBone';
import FitImmune from './components/main/wellness/FitImmune';
import MetabolismDash from './components/main/wellness/MetabolismDash';
import GlucoseMeta from './components/main/wellness/GlucoseMeta';
import CholMeta from './components/main/wellness/CholMeta';
import O2Meta from './components/main/wellness/O2Meta';
import EasternDash from './components/main/wellness/EasternDash';
import YinYang from './components/main/wellness/YinYang';
import Sensitivity from './components/main/wellness/Sensitivity';
import Circulation from './components/main/wellness/Circulation';
import BasicVitalDash from './components/main/healthness/BasicVitalDash';
import CoreVitalDash from './components/main/healthness/CoreVitalDash';
import WellnessDash from './components/main/wellness/WellnessDash';
import FitnessDash from './components/main/wellness/fitnessdash';
import Profile from './components/main/profile/profile';
import { scanRecordsListner, subStateListner } from './components/firebase/auth';
import SubModal from './components/main/profile/submodal';
import Disclaimer from './components/main/disclaimer';
import Report from './components/main/Report';
import { ModalCtxProvider } from './components/store/modalcontext';
import { ModalCtxProvider2 } from './components/store/modalcontext2';
import HowToUseModal from './components/common/howtomodal';
import Faq from './components/main/faq/faq';
import LandingPage from './components/main/LandingPage';
import RedirectScanModal from './components/common/redirectScanModal';
import PrivacyPolicy from "./components/common/privacypolicy";
import HowToScanModal from './components/common/howtoScanModal';

function RequireAuth({children}){

  if(localStorage.getItem("status") === "true"){
    return children
  }else{
    window.location.href="https://my.playcarehealth.com/"
  }
}

function App() {
  const [navbar, setNavBar]=useState(false)
  const [class1, setClass1]=useState("")
  const [class2, setClass2]=useState("")
  let authurls=['/','/profile']
  let scanruls = ['/scan']
  const location =window.location.pathname
  let [trial, setTrial] = React.useState(true);
  let [redirectToScan,setRedirectToScan]=useState(true);
  let [inScanPage,setInScanPage]=useState(true);
  // const RequireSubscription = async()=>{
  //   let subValue = await subStateListner();
  //   setTrial(subValue)
  //   console.log('Sub Value in location : ',subValue)
  // }
  // const siteLocation = useLocation();

  const RequireScanRecords = async()=>{
    let recordStatus = await scanRecordsListner();
    setRedirectToScan(recordStatus);// false means show popup , true means not show popup 
  }

  // useEffect(()=>{
  //   console.log('Location : ',siteLocation.pathname)
  //   RequireSubscription()
  // },[siteLocation])

  useEffect(()=>{
    RequireScanRecords()
  })

  let checkoccurance=authurls.includes(location);
  let scanoccurance = scanruls.includes(location);

  useEffect(()=>{
    setUp()
    setUpscan()
  },[location])

  const setUpscan=( )=>{
    if(scanoccurance){
      setInScanPage(true)
    }else{
      setInScanPage(false)
    }
  }

  const setUp=( )=>{
    if(checkoccurance){
      setNavBar(false)
      setClass2("")
      setClass1("")
    }else{
      setNavBar(true)
      setClass2("p-4    rounded-lg  mt-14")
      setClass1("p-4 sm:ml-64")
    }
  }
 
  return (
    // <ModalCtxProvider
    <ModalCtxProvider2 className="font-montserrat">
      <HowToUseModal />
      <ModalCtxProvider className="font-montserrat">
        <HowToScanModal/>
        <Router>
          {navbar ? (
            <>
              <RequireAuth>
                {/* <RequireSubscription> */}
                <Nav />
                {/* </RequireSubscription> */}
              </RequireAuth>
              {/* {trial ? null : (
                <>
                  <SubModal />
                </>
              )} */}
              {redirectToScan || inScanPage ? null : (
                <>
                  <RedirectScanModal/>
                </>
              )}
              <SubModal />
            </>
          ) : null}
          <div class={class1}>
            <div class={class2}>
              <Routes>
                <Route
                  path="/healthnessdashboard"
                  element={
                    <RequireAuth>
                      <HealthnessDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route path="/faq" element={<Faq />}>
                  {" "}
                </Route>
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="/skindash"
                  element={
                    <RequireAuth>
                      <SkinDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route path="/" element={<LandingPage />}>
                  {" "}
                </Route>
                {/* <Route path="signup" element={<SignUP />}>
                  {" "}
                </Route> */}
                {/* <Route
                  path="subscription"
                  element={
                    <RequireAuth>
                      <Subscription />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route> */}
                <Route
                  path="cleansers"
                  element={
                    <RequireAuth>
                      <Cleansers />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="moisturizers"
                  element={
                    <RequireAuth>
                      <Moisturizers />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="scan"
                  element={
                    <RequireAuth>
                      <Scan />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="report"
                  element={
                    <RequireAuth>
                      <Report />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="healthtips"
                  element={
                    <RequireAuth>
                      <HealthTips />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="sunscreen"
                  element={
                    <RequireAuth>
                      <Sunscreen />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="soothingsensitiveskin"
                  element={
                    <RequireAuth>
                      <SoothingSensitiveSkin />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="exfoliants"
                  element={
                    <RequireAuth>
                      <Exfoliants />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="wellness"
                  element={
                    <RequireAuth>
                      <WellnessDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="dietdashboard"
                  element={
                    <RequireAuth>
                      <DietDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="privacy-policy"
                  element={
                    <RequireAuth>
                      <PrivacyPolicy />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitamindashboard"
                  element={
                    <RequireAuth>
                      <VitaminDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitamind"
                  element={
                    <RequireAuth>
                      <VitaminD />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitaminc"
                  element={
                    <RequireAuth>
                      <VitaminC />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitaminbdashboard"
                  element={
                    <RequireAuth>
                      <VitaminBDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="mineralsdash"
                  element={
                    <RequireAuth>
                      <MineralsDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="nutrientsdash"
                  element={
                    <RequireAuth>
                      <NutrientsDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitamine"
                  element={
                    <RequireAuth>
                      <VitaminE />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitaminb3"
                  element={
                    <RequireAuth>
                      <VitaminB3 />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitaminb12"
                  element={
                    <RequireAuth>
                      <VitaminBComplex />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="vitaminb9"
                  element={
                    <RequireAuth>
                      <VitaminB9 />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="magnesium"
                  element={
                    <RequireAuth>
                      <MineralMg />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="calcium"
                  element={
                    <RequireAuth>
                      <MineralCa />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="iron"
                  element={
                    <RequireAuth>
                      <MineralFe />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="phosphorous"
                  element={
                    <RequireAuth>
                      <MineralPh />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="probiotics"
                  element={
                    <RequireAuth>
                      <NutriProbio />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="ltheanine"
                  element={
                    <RequireAuth>
                      <NutriLTh />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="policosanol"
                  element={
                    <RequireAuth>
                      <NutriPsa />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="omega3"
                  element={
                    <RequireAuth>
                      <NutriOm3 />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="redyeastrice"
                  element={
                    <RequireAuth>
                      <NutriRedRice />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="fitnessdash"
                  element={
                    <RequireAuth>
                      <FitnessDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="profile"
                  element={
                    <RequireAuth>
                      <Profile />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="stress"
                  element={
                    <RequireAuth>
                      <FitStress />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="fatigue"
                  element={
                    <RequireAuth>
                      <FitFatigue />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="bonehealth"
                  element={
                    <RequireAuth>
                      <FitBone />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="immunity"
                  element={
                    <RequireAuth>
                      <FitImmune />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="metabolismdash"
                  element={
                    <RequireAuth>
                      <MetabolismDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="glucosemetabolism"
                  element={
                    <RequireAuth>
                      <GlucoseMeta />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="cholesterolmetabolism"
                  element={
                    <RequireAuth>
                      <CholMeta />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="o2metabolism"
                  element={
                    <RequireAuth>
                      <O2Meta />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="easternmedicine"
                  element={
                    <RequireAuth>
                      <EasternDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="yinyang"
                  element={
                    <RequireAuth>
                      <YinYang />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="sensitivity"
                  element={
                    <RequireAuth>
                      <Sensitivity />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="circulation"
                  element={
                    <RequireAuth>
                      <Circulation />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="anti-aging"
                  element={
                    <RequireAuth>
                      <AntiAging />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="brightening"
                  element={
                    <RequireAuth>
                      <Brightening />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="acne-fighting"
                  element={
                    <RequireAuth>
                      <AcneFighting />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="basicvitals"
                  element={
                    <RequireAuth>
                      <BasicVitalDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="basicvitals/spo2"
                  element={
                    <RequireAuth>
                      <Spo2 />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="corevitals"
                  element={
                    <RequireAuth>
                      <CoreVitalDash />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="corevitals/bloodpressure"
                  element={
                    <RequireAuth>
                      <BloodPressure />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="corevitals/cholestoral"
                  element={
                    <RequireAuth>
                      <Cholesterol />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="corevitals/bloodsugar"
                  element={
                    <RequireAuth>
                      <BloodSugar />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                {/* <Route path="reset-password" element={<ResetPassword />}>
                  {" "}
                </Route> */}
                <Route
                  path="basicvitals/heart-rate"
                  element={
                    <RequireAuth>
                      <HeartRate />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                <Route
                  path="basicvitals/breathing-rate"
                  element={
                    <RequireAuth>
                      <BreathingRate />
                    </RequireAuth>
                  }
                >
                  {" "}
                </Route>
                {/* <Route path="/healthness" element = {<Healthness />}> </Route>
            <Route path="/wellness" element = {<Wellness />}> </Route>
            <Route path="/skin_health" element = {<SkinHealth />}> </Route> */}
              </Routes>

              {navbar ? (
                <>
                  <RequireAuth>
                    {/* <RequireSubscription> */}
                    <Footer />
                    {/* </RequireSubscription> */}
                  </RequireAuth>
                </>
              ) : null}
              <Disclaimer />
            </div>
          </div>
        </Router>
    </ModalCtxProvider>
    </ModalCtxProvider2>
    
  );
}

export default App;
