import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import menuIcon from "./menu-icon.png";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleHealthinessClick = (event) => {
    event.preventDefault();
    setIsExpand(!isExpand);
    window.history.pushState({}, "", isExpand
      ? "/healthiness-dashboard"
      : "/healthiness-dashboard?dropdown=open");
  };

  return (
    <div className={`h-screen fixed left-0 top-0 z-50 ${isOpen ? "w-64" : "w-16"} bg-gray-900`}>
      <div className="flex items-center justify-center h-16">
        {/* <img src={menuIcon} alt="menu icon" onClick={toggleMenu} /> */}
        <button
          className="p-4 text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h8m-8 6h16"
              />
            )}
          </svg>
        </button>
      </div>
      <div className="mt-10">
        <button
            onClick={handleHealthinessClick}
            className="text-white hover:bg-gray-700 p-2 rounded block mb-2"
        >
            Healthiness
        </button>
        {isExpand && (
        <ul className="list-reset">
          <li className="text-white p-2 hover:bg-gray-700">
            <Link className={`px-4 py-2 mt-2 text-white font-medium block ${location.pathname === "/basic-vital" ? "bg-gray-700" : ""}`} to="/basic-vital" >Basic Vital</Link>
          </li>
          <li className="text-white p-2 hover:bg-gray-700">
            <Link className={`px-4 py-2 mt-2 text-white font-medium block ${location.pathname === "/core-vital" ? "bg-gray-700" : ""}`} to="/core-vital">Core Vital</Link>
          </li>
          
        </ul>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
