import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VitalsReport from "./VitalsReport";
import "../report.css";
import guy from "../../assets/images/VitalPic.png";
// import logo from "../assets/images/Docsun logo.png";
import logo from "../imgs/logo.png";
import { fetchUserInfo } from "../firebase/auth";
import Tooltip from "../common/Tooltip";

function Scan() {
  let navigate = useNavigate();
  function changeScan() {
    // let new_path ="/scan";
    // navigate(new_path);
    window.location.href = "/scan";
  }

  return (
    <>
      <section className="scanning">
        {/* <div className="">
                <button className="scan" onClick={changeScan}>Back to Scan</button>
            </div> */}

        <div className="column">
          <p>
            Here is your report of the scanning including 6 vital monitoring and
            11 indexes that we generated as a reference for your personal body
            status management.
          </p>
        </div>
      </section>
      <div class="group flex relative w-full  my-auto mr-20">
        <Tooltip
          text="Shows the level, normal range, result figure, and unit of the 6
          vitals."
        />
      </div>
    </>
  );
}

function Report() {
  const [fullName, setFullName] = useState("");
  const { state } = useLocation();
  const obj = state?.basic;
  // console.log("New Data: ",state);
  const getUserInfo = async () => {
    let scanInfo = await fetchUserInfo();
    if (scanInfo.status) {
      setFullName(`${scanInfo.firstName} ${scanInfo.lastName}`);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, [state]);
  return (
    <div className="container">
      <div class="group flex relative w-full my-auto">
        {/* <span class=" text-white mr-5">
          <i
            class="fa fa-info-circle text-blue-800 w-10"
            aria-hidden="true"
          ></i>
        </span>
        <div
          class="group-hover:opacity-100 transition-opacity bg-blue-900 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-1/2 opacity-0  mx-auto py-3"
        >
          Shows the report header, including user name, scan date, and time.
        </div> */}
        <Tooltip text="Shows the report header, including user name, scan date, and time." />
      </div>
      <section className="upper">
        <div className="inner-row">
          <div className="column guy md:w-1/2 md:h-1/2">
            <img className="profile" src={guy} />
          </div>
          <div className="column hms-text">
            <p>VitalPic Non-contact Report</p>
            <p>Date: {new Date().toLocaleDateString("en-ZM")}</p>
            <p>Time: {new Date().toLocaleTimeString("en-ZM")}</p>
            <p>Account : {fullName}</p>
          </div>
        </div>

        <div className="column logo-col">
          {/* <img className="col-logo" src={logo} alt="aaaa" /> */}
        </div>
      </section>

      <Scan />

      <VitalsReport />
    </div>
  );
}

export default Report;
