import React, { useEffect, useState } from 'react';
import Layout from '../layout';
// import bg from "../../../assets/01.jpg";
import bg from "../../../assets/Healthiness/Corevitals.jpg";
import BRICON from "../../../assets/Healthiness/Recomendations.jpg";
import Icon from '../../../assets/Healthiness/Icon Blood sugar.png'
import Icon3 from '../../../assets/Healthiness/02.png'
import MyLineChart from '../dashboard/linechart';
import MyChart2 from '../dashboard/mychart2';
import Icon2 from '../../../assets/Healthiness/01.png'
import Icon4 from '../../../assets/Healthiness/03.png'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {  fetchLatestScore } from '../../firebase/scans';

const color="red-600"
const HealthTips = () => {
    const [lifestyle,setLifeStyle] = useState("");
    const [fitness,setFitness] = useState("");
    const [supplement,setSupplement] = useState("");
    const setLatestData = async()=>{
        let resp = await fetchLatestScore(9,'HEI');
        if (resp.score !== 0){ 
            setLifeStyle(resp.recommend.lifestyle);
            setFitness(resp.recommend.fitness);
            setSupplement(resp.recommend.supplement);
        }else{
            setLifeStyle("");
            setSupplement("");
            setFitness("");
        }
    }
    useEffect(()=>{
        setLatestData();
    },[])
    return ( 
       
            <div 
             style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
             className="h-screenm flex flex-col space-y-3 pt-8 pl-4"
             >

                
                <div className='flex flex-col md:flex-row md:space-x-4 space-x-0 space-y-2 md:space-y-0'>
                    <div className='md:w-1/4'>
                    <img src={Icon2} className="" />

                    </div>
                    <div className='md:w-3/4 md:px-4 w-full'>
                    <div className={`bg-${color} text-white font-bold rounded-t-lg p-2 `}>
                    Lifestyle Tips

                    </div>
                    
                    <div className='flex flex-row border-b py-3'>
                        
                        <div className='my-auto'>
                        <ul className='list-disc pl-8'>
                            <li>
                            {lifestyle}
                            </li>
                            {/* <li>
                            Enrich diet with fortified foods.
                            </li>
                            <li>
                            Consume Vitamin D rich foods.
                            </li> */}
                        </ul>
                        </div>

                    </div>

                    </div>

                </div>
                <div className='flex flex-col md:flex-row md:space-x-4 space-x-0 space-y-2 md:space-y-0'>
                    <div className='md:w-1/4'>
                    <img src={Icon3} className="" />

                    </div>
                    <div className='md:w-3/4 md:px-4 w-full'>
                    <div className={`bg-${color} text-white font-bold rounded-t-lg p-2 `}>
                    Fitness Tips

                    </div>
                    
                    <div className='flex flex-row border-b py-3'>
                        
                        <div className='my-auto'>
                        <ul className='list-disc pl-8 text-gray-800'>
                            <li>
                            {fitness}
                            </li>
                            {/* <li>
                            Enrich diet with fortified foods.
                            </li>
                            <li>
                            Consume Vitamin D rich foods.
                            </li> */}
                        </ul>
                        </div>

                    </div>

                    </div>

                </div>
                <div className='flex flex-col md:flex-row md:space-x-4 space-x-0 space-y-2 md:space-y-0'>
                    <div className='md:w-1/4'>
                    <img src={Icon4} className="" />

                    </div>
                    <div className='md:w-3/4 md:px-4 w-full'>
                    <div className={`bg-${color} text-white font-bold rounded-t-lg p-2 `}>
                    Suggested Supplements

                    </div>
                    
                    <div className='flex flex-row border-b py-3'>
                        
                        <div className='my-auto'>
                        <ul className='list-disc pl-8 text-gray-800'>
                            <li>
                            {supplement}
                            </li>
                            {/* <li>
                            Enrich diet with fortified foods.
                            </li>
                            <li>
                            Consume Vitamin D rich foods.
                            </li> */}
                        </ul>
                        </div>

                    </div>

                    </div>

                </div>
                


                
                

                

                {/* <div className='flex'>

                    <button className='bg-blue-900 text-white font-bold mx-auto rounded-lg px-7 mt-4 md:mt-12'>
                        Generate a PDF report
                    </button>
                </div> */}

                

            </div>


       
     );
}
 
export default HealthTips;