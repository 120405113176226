import React from 'react';
import Layout from '../layout';

import Cleansers from '../../../assets/Skin health/icon Cleansers.png'
import Moisturizers from '../../../assets/Skin health/Moisturizers.png'
import Sunscreen from '../../../assets/Skin health/Sunscreen.png'
import Exfoliants from '../../../assets/Skin health/Exfoliants.png'
import Antiaging from '../../../assets/Skin health/Anti-aging.png'
import Brightening from '../../../assets/Skin health/Brightening.png'
import Acnefighting from '../../../assets/Skin health/Acne-fighting.png'
import NaturalOrganic from '../../../assets/Skin health/Natural Organic.png'
import Soothingsensitiveskin from '../../../assets/Soothing sensitive skin.png'
import bgm from '../../../assets/Skin health/Skinhealthmenurolldown.png'
import bg from "../../../assets/Skin health/Skinhealthmenurolldown.png";
import Tooltip from '../../common/Tooltip';
const SkinHealthDash = () => {
    return (
      <>
        {/* <Layout> */}

        <div
          style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
          className="min-h-full p-10"
        >
          <div className="border rounded-lg flex flex-col ">
            {/* Start of row */}
            <div className="flex flex-row">
              <a
                href="/cleansers"
                className="flex flex-row w-1/3 border-r p-2 transition duration-0 hover:duration-150 hover:bg-gray-300 rounded-tl"
              >
                <div>
                  <img src={Cleansers} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-red-500 flex flex-row justify-between">
                  <div>Cleansers</div>
                  <div>
                    <Tooltip
                      text="This shows the 7-day trending of cleansers with the average. Click the right arrow to check the detail.
"
                    />
                  </div>
                </div>
              </a>
              <a
                href="/moisturizers"
                className="flex flex-row w-1/3 border-r p-2 hover:bg-gray-300"
              >
                <div>
                  <img src={Moisturizers} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-red-500 flex flex-row justify-between">
                  Moisturizers
                  <Tooltip
                    text="This shows the 7-day trending of Moisturizers with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              <a
                href="/sunscreen"
                className="flex flex-row w-1/3  p-2 hover:bg-gray-300 rounded-tr"
              >
                <div>
                  <img src={Sunscreen} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-red-700 flex flex-row justify-between">
                  Sunscreen
                  <Tooltip
                    text="This shows the 7-day trending of Sunscreen with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
            </div>
            {/* End of row */}
            {/* Start of row */}
            <div className="flex flex-row">
              {/* <div className="flex flex-row md:w-1/3 border-r border-t p-2 "> */}
              <a
                href="/exfoliants"
                className="flex flex-row w-1/3 border-r p-2 transition duration-0 hover:duration-150 hover:bg-gray-300 rounded-tl"
              >
                <div>
                  <img src={Exfoliants} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-yellow-500 flex flex-row justify-between">
                  Exfoliants
                  <Tooltip
                    text="This shows the 7-day trending of Exfoliants with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              {/* </div> */}
              {/* <div className="flex flex-row w-1/3 border-r border-t p-2"> */}
                <a
                  href="/anti-aging"
                  className="flex flex-row w-1/3 border-r p-2 hover:bg-gray-300"
                >
                <div>
                  <img src={Antiaging} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-yellow-600 flex flex-row justify-between" >
                  Anti-aging
                  <Tooltip
                    text="This shows the 7-day trending of Anti-aging with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              {/* </div> */}
              {/* <div className="flex flex-row w-1/3 border-r border-t p-2"> */}
                <a
                  href="/brightening"
                  className="flex flex-row w-1/3 border-r p-2 hover:bg-gray-300"
                >
                <div>
                  <img src={Brightening} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-orange-600 flex flex-row justify-between">
                  Brightening
                  <Tooltip
                    text="This shows the 7-day trending of Brightening with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              {/* </div> */}
            </div>
            {/* End of row */}
            {/* Start of row */}
            <div className="flex flex-row">
              {/* <div className="flex flex-row w-1/3 border-r border-t p-2"> */}
                <a
                  href="/acne-fighting"
                  className="flex flex-row w-1/3 border-r p-2 transition duration-0 hover:duration-150 hover:bg-gray-300 rounded-tl"
                >
                <div>
                  <img src={Acnefighting} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-teal-500 flex flex-row justify-between">
                  Acne-fighting
                  <Tooltip
                    text="This shows the 7-day trending of Acne-fighting with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              {/* </div> */}
              {/* <div className="flex flex-row w-1/3 border-r border-t p-2">
                <div>
                  <img src={NaturalOrganic} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-sky-700">
                  Natural Organic
                  <Tooltip
                    text="This shows the 7-day trending of Natural Organic with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </div> */}
              {/* <div className="flex flex-row w-1/3 border-r border-t p-2"> */}
                <a
                  href="/soothingsensitiveskin"
                  className="flex flex-row w-1/3 border-r p-2 hover:bg-gray-300"
                >
                <div>
                  <img src={Soothingsensitiveskin} className="h-10" />
                </div>
                <div className="my-auto ml-3 font-bold text-sky-900 flex flex-row justify-between">
                  Soothing sensitive skin
                  <Tooltip
                    text="This shows the 7-day trending of Soothing sensitive skin with the average. Click the right arrow to check the detail.
"
                  />
                </div>
              </a>
              {/* </div> */}
            </div>
            {/* End of row */}
          </div>
        </div>

        {/* </Layout> */}
      </>
    );
}
 
export default SkinHealthDash;