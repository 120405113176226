import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/v2/Vitamin B.png'
import VitalIcon from '../../../assets/wellness/v2/Vitamin C.png'
import RecIcon from '../../../assets/wellness/v2/Vitamin D.png'
import EIcon from '../../../assets/wellness/v2/Vitamin E.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import Tooltip from '../../common/Tooltip';
import LeftSection from '../../common/LeftSection';

const VitaminDash = () => {
    const [schartData,setSChartData]=useState([]);
    const [fchartData,setFChartData]=useState([]);
    const [bchartData,setBChartData]=useState([]);
    const [ichartData,setIChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let sresp = await fetchWeeklyData(26,'Vitamin B');
        let fresp = await fetchWeeklyData(30,'Vitamin C');
        let bresp = await fetchWeeklyData(31,'Vitamin D');
        let iresp = await fetchWeeklyData(32,'Vitamin E');
        if (bresp[0] !== 0){
            setSChartData(sresp);
            setFChartData(fresp);
            setBChartData(bresp);
            setIChartData(iresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    return (
      // <Layout>

      <div
        className=" min-h-screen1  w-full flex flex-col md:flex-row pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        
        <div className='md:w-3/4 grow'>

        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Vitamin B
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of vitamin B-related items with the average. Click the right arrow to check the detail.

"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={schartData} />
            </div>
            <a
              href="/vitaminbdashboard"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Vitamin C
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of vitamin C-related items with the average. Click the right arrow to check the detail.

"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={fchartData} />
            </div>
            <a
              href="/vitaminc"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-3">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Vitamin D
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of vitamin D-related items with the average. Click the right arrow to check the detail.

"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a
              href="/vitamind"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-1">
          <div className="flex flex-row mb-1 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={EIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Vitamin E
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of vitamin E-related items with the average. Click the right arrow to check the detail.

"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row md:w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={ichartData} />
            </div>
            <a
              href="/vitamine"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        </div>
        <div className='flex'>
          <LeftSection />

        </div>
      </div>

      // </Layout>
    );
}
 
export default VitaminDash;