import React from "react";
import { Link } from "react-router-dom";

function Disclaimer() {
  return (
    <div class="relative inset-x-0 bottom-0 mx-auto my-3 p-4 text-center bg-white rounded-lg shadow mb-16">
      <div className="flex flex-col">
        <p class="text-sm text-gray-500">
          This tool is not intended to diagnose, treat, cure, or prevent any
          disease. Always feel free to consult your healthcare provider.
        </p>
        <div>
          <Link to="/privacy-policy" className="text-blue-500 underline">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;
