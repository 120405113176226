import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

function DashChart(props) {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Week Overview',
        },
      },
      scales:{
        y:{
          beginAtZero:true,
        }
      }
    };
    const data = {
      labels,
      datasets: [
        {
          label: 'Healthness',
          data: props.data[0],
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Wellness',
          data: props.data[1],
          borderColor: 'rgb(247, 199, 5)',
          backgroundColor: 'rgba(247, 199, 5,0.5)',
        },
        {
          label: 'Skin Health',
          data: props.data[2],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
      ],
    };
  return (
  <Line options={options} data={data} className="h-10" style={{hi
  :'10px'}} />
  );
}

export default DashChart
