import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/VitalPic.png";
import { useLocation, useNavigate } from "react-router-dom";
import { registerCustomerWithFirebase, signInAnnonmousWithFirebase, signInUsingCustomerIDWithFirebase } from "../firebase/auth";
import { getCustomerId, getJWTToken } from "../firebase/ttapi";
import { signInAnonymously } from "firebase/auth";

const LandingPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [message,setMessage]=useState('');
    const [progressValue,setProgressValue]=useState('1%');
    const [textColor,setTextColor]=useState('');
    const navigate = useNavigate();
    const checkAndStoreSSOCustomerInfo = async()=>{ // this will be copied to the landing page if we are implementing landing page
        let ssoKey = params.get('ssoKey')
        let jwtResp = await getJWTToken();
        if (ssoKey != null){
            if (jwtResp){
                setTextColor('text-blue-400')
                setProgressValue('45%')
                let customerIdResp = await getCustomerId(ssoKey);
                if (customerIdResp){
                    // check if customerID exists in our db 
                    setProgressValue('75%')
                    let signInStatus = await signInAnnonmousWithFirebase();
                    if (signInStatus){
                        let checkCustomerExists = await signInUsingCustomerIDWithFirebase();
                        if (checkCustomerExists.status){
                            setProgressValue('90%')
                            // navigate('/dashboard')
                            window.location.href = '/dashboard'

                        }else{
                            setProgressValue('90%')
                            let respStatus =  await registerCustomerWithFirebase();
                            if (respStatus){
                                setProgressValue('100%')
                                setMessage('Loading assets for your personalised wellness tracking')
                                window.location.href = '/dashboard'
                                // navigate('/dashboard')
                            }else{
                                setTextColor('text-red-400')
                                setMessage('Your account does not have the required permission to use this site !')
                                setProgressValue('0%')
                            }
                        }
                    }else{
                        setTextColor('text-red-400')
                        setMessage('Your account does not have the required permission to use this site !')
                        setProgressValue('0%')
                    }
                }else{
                    setTextColor('text-red-400')
                    setMessage('Your account does not have the required permission to use this site !')
                    setProgressValue('0%')
                }
            }else{
                setTextColor('text-red-400')
                setMessage('Your account does not have the required permission to use this site !')
                setProgressValue('0%')
            }
        }else{
            setTextColor('text-red-400')
            setMessage('Invalid or Expired SSO Key')
            setProgressValue('0%')
        }
    }
    useEffect(()=>{
        setTextColor('text-blue-400')
        setMessage('Please wait, while we prepare your personalised vitalpic dashboard')
        setProgressValue('15%')
        checkAndStoreSSOCustomerInfo();
    },[])
    return (
        <div className="h-screen bg-gradient-to-b from-white to-gray-300 flex flex-col justify-center items-center">
        <img
            src={Logo}
            alt="Placeholder"
            className="mb-10 md:w-1/2 md:h-1/2"
        />
        <h2 className={`${textColor} font-bold text-2xl font-montserrat mb-4`}>
            {message}
        </h2>
        <div className="w-1/2 rounded-full overflow-hidden h-4">
            <div className="bg-blue-500 h-full" style={{ width: progressValue }}></div>
        </div>
        </div>
    );
};

export default LandingPage;