import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/Healthiness/Icon BP.png'
import VitalIcon from '../../../assets/Healthiness/Icon Blood sugar.png'
import RecIcon from '../../../assets/Healthiness/Icon Cholesterol.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import Tooltip from '../../common/Tooltip';

const CoreVitalDash = () => {
    const [bchartData,setBChartData]=useState([]);
    const [cchartData,setCChartData]=useState([]);
    const [hchartData,setHChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let bresp = await fetchWeeklyData(6,'Blood Pressure');
        let cresp = await fetchWeeklyData(7,'Blood Sugar');
        let hresp = await fetchWeeklyData(8,'Total Cholesterol');
        if (bresp[0] !== 0){
            setBChartData(bresp);
            setCChartData(cresp);
            setHChartData(hresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    return (
      <div
        className="h-screen w-full flex flex-col pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Blood Pressure
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Blood Pressure with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a
              href="/corevitals/bloodpressure"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Blood Sugar
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Blood Sugar with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={cchartData} />
            </div>
            <a
              href="/corevitals/bloodsugar"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-24">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Cholesterol
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Cholesterol with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={hchartData} />
            </div>
            <a
              href="/corevitals/cholestoral"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
      </div>
    );
}
 
export default CoreVitalDash;