import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/v2/Stress.png'
import VitalIcon from '../../../assets/wellness/v2/Fatigue.png'
import RecIcon from '../../../assets/wellness/v2/Bone Health.png'
import IIcon from '../../../assets/wellness/v2/Immune functioon.png'
import MIcon from '../../../assets/wellness/v2/Metabolism.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import Tooltip from '../../common/Tooltip';
import LeftSection from '../../common/LeftSection';

const FitnessDash = () => {
    const [schartData,setSChartData]=useState([]);
    const [fchartData,setFChartData]=useState([]);
    const [bchartData,setBChartData]=useState([]);
    const [ichartData,setIChartData]=useState([]);
    const [mchartData,setMChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let sresp = await fetchWeeklyData(12,'Stress');
        let fresp = await fetchWeeklyData(13,'Fatigue');
        let bresp = await fetchWeeklyData(14,'Bone Health');
        let iresp = await fetchWeeklyData(15,'Immune Function');
        let mresp = await fetchWeeklyData(16,'Body Metabolism');
        if (bresp[0] !== 0){
            setSChartData(sresp);
            setFChartData(fresp);
            setBChartData(bresp);
            setIChartData(iresp);
            setMChartData(mresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    const color='blue-400'
    return (
      <div
        className="min-h-screen1 bg-repeat-y bg-center w-full flex flex-col md:flex-row pl-3"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className='grow md:w-3/4'>

        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Stress
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of stress with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row md:w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={schartData} />
            </div>
            <a
              href="/stress"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Fatigue
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Fatique with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={fchartData} />
            </div>
            <a
              href="/fatigue"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Bone Health
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Bone Health with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a
              href="/bonehealth"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={IIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Imune Function
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Imune Function with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={ichartData} />
            </div>
            <a
              href="/immunity"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={MIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Body Metabolism
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Body Metabolism with the average. Click the right arrow to check the detail.
"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={mchartData} />
            </div>
            <a
              href="/metabolismdash"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        </div>
        <div className='flex'>
          <LeftSection />
        </div>
      </div>
      
    );
}
 
export default FitnessDash;