import React, { useContext, useEffect } from "react";
import ModalCtx2 from "../store/modalcontext2";
import inst_video_1 from "../../assets/video/howtouse.webm";
import inst_video_2 from "../../assets/video/howtouse.mp4";

// import { Link, useNavigate } from "react-router-dom";
// import { fetchSubscriptionInfo,updateFirebaseSubscriptionStatusUsingPayPal } from "../../firebase/auth";
export default function HowToUseModal() {
  const [showModal, setShowModal] = React.useState(true);
  //   const navigate = useNavigate();

  const iOS =
    !!window.navigator.platform &&
    /iPad|iPhone|iPod/.test(window.navigator.platform);
  const ctnx = useContext(ModalCtx2);
  useEffect(() => {
    // checkScan();
  }, []);

  return (
    <>
      {ctnx.isOpen ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                {/*body*/}
                <div className="mt-2">
                  <video
                    // ref={vidRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                    // hidden={hideVideo}
                    className="inst__video"
                  >
                    {iOS ? (
                      <source src={inst_video_2} type="video/mp4"></source>
                    ) : (
                      <source src={inst_video_1} type="video/webm"></source>
                    )}
                  </video>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <div className="mt-4">
                    <button
                      type="button"
                      onClick={()=>ctnx.toggleIsOpen()}
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      Close Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
