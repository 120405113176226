import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Circle = (props) => {
    return (  
        <div style={{ 
            width: 18, height: 18,textColor: 'white',
            trailColor: '#d6d6d6',
    // backgroundColor: '#3e98c7'
     }} className="mr-2">
            {/* <CircularProgressbar value={props.score} /> */}

            <CircularProgressbar styles={{path:{stroke:'#f7fcfc',strokeWidth: '15'},trail:{stroke:'#9fa1a1'}}} value={Number(((props.score / 3.0) * 100).toFixed(1))} />
            </div>

        // <CircularProgressbar value={percentage} text={`${percentage}%`} style={{}} />
        // <div className="radial-progress text-white bg-gray-400 mr-1" style={{ "--value": "70", "--size": "1rem", "--thickness": "2px" }}></div>
    );
}
 
export default Circle;