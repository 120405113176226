import React, { createContext, useState } from "react";

const ModalCtx = createContext();

export function ModalCtxProvider(props) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  const context = {
    isOpen: isOpen,
    toggleIsOpen: toggleIsOpen,
  };
  return (
    <ModalCtx.Provider value={context}>{props.children}</ModalCtx.Provider>
  );
}

export default ModalCtx;
