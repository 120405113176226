import React, { useContext } from "react";
import FAQ from "../../assets/nav/FAQ.png";
import HOW from "../../assets/nav/How to use.png";
import SUP from "../../assets/nav/Support.png";
import ModalCtx2 from "../store/modalcontext2";
import { Link } from "react-router-dom";
export default function LeftSection() {
  const ctnx = useContext(ModalCtx2);
  // console.log(ctnx);
  return (
    <div className="pyh-10 flex flex-col justify-between pt-20 md:mb-8  ">
      <div className="flex flex-col space-y-4">
        <div>
          <Link
            to="/faq"
            class="relative inline-flex items-center justify-center  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group   hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <img src={FAQ} c className="h-12" />
          </Link>
        </div>
        <div>
          <button
            onClick={() => ctnx.toggleIsOpen()}
            class="relative inline-flex items-center justify-center  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group   hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            <img src={HOW} c className="h-12" />
          </button>
        </div>
      </div>
      <div>
        <a
          href="mailto:support@playcarehealth.com"
          class="relative inline-flex items-center justify-center  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group   hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 "
        >
          <img src={SUP} c className="h-12" />
        </a>
      </div>
    </div>
  );
}
