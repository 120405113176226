import React, { useEffect, useState } from "react";
import "../report.css";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  // Tooltip,
  PieChart,
  Pie,
  Cell,
  Label,
  ResponsiveContainer,
} from "recharts";

import excellent_box_meter from "../../assets/images/Box meter 02.png";
import good_box_meter from "../../assets/images/Box meter 03.png";
import average_box_meter from "../../assets/images/Box meter 06.png";
import below_average_box_meter from "../../assets/images/Box meter 01.png";
import poor_box_meter from "../../assets/images/Box meter 05.png";

import _3_score_fair_meter from "../../assets/images/3 level Meter yellow.png";
import _3_score_good_meter from "../../assets/images/3 level Meter green.png";
import _3_score_bad_meter from "../../assets/images/3 level Meter red.png";

import _4_score_excellent_meter from "../../assets/images/4 level Meter Dark green.png";
import _4_score_good_meter from "../../assets/images/4 level Meter green.png";
import _4_score_fair_meter from "../../assets/images/4 level Meter yellow.png";
import _4_score_bad_meter from "../../assets/images/4 level Meter red.png";
import { fetchDataForNav } from "../firebase/scans";
import Tooltip from "../common/Tooltip";

function RadarComponent({ radar_state }) {
  return (
    <RadarChart height={300} width={600} outerRadius="80%" data={radar_state}>
      <PolarGrid />
      <PolarAngleAxis dataKey="name" />
      <Radar dataKey="x" stroke="green" fill="green" fillOpacity={0.5} />
    </RadarChart>
  );
}

function VitalsRowData(props) {
  return (
    <tr>
      <td className="mv-left">{props.vital}</td>
      <td>{props.result}</td>
      <td>{props.normal}</td>
      <td>{props.units}</td>
      <td>
        <img className="box-meter-img " src={props.img_src} alt="" />
      </td>
    </tr>
  );
}

function IndexRowData(props) {
  // const [show_more, setShowMore] = React.useState(false)

  // function handleClick()
  // {
  //     setShowMore(!show_more);
  // }

  //the state is handled by the parent component
  //it passes event for when the blue arrow is clicked back to the children
  //Sharing state between components (Lifting state up)

  return (
    <>
      <tr>
        <td>{props.indexName}</td>
        {/* <td>
                <img style={{height: '20px'}} className="dd-btn" src={blue_arrow} 
                alt="" onClick={props.onShow} />
            </td> */}
        <td>{props.score}</td>
        <td>
          <img className="meter" src={props.img_src} alt="" />
        </td>
        <td>{props.short_desc}</td>
      </tr>

      {/* {props.show_more  &&  props.children} */}
    </>
  );
}

function Description({ score_name, message = ["", ""], radar_state }) {
  return (
    <tr className="tlight">
      <td colspan="12">
        <div>
          <div className="row">
            <div className="col-4">
              <RadarComponent radar_state={radar_state} />
            </div>
            <div className="col-8">
              <h3 className="scoreHead">{score_name}</h3>
              {/* <p>{message}</p> */}
              <ul className="word__ul">
                {message.map((d) => (
                  <div>
                    <li className="word__li">✔️ {d}</li>
                    <br />
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

function VitalsReport() {
  const [reportData, setReportData] = useState({});
  const setDataForReport = async () => {
    let resp = await fetchDataForNav();
    if (resp.status) {
      setReportData(resp.data[0]);
    } else {
      setReportData({});
    }
  };
  useEffect(() => {
    setDataForReport();
  }, []);
  let box_meter = {
    excellent: excellent_box_meter,
    average: average_box_meter,
    good: good_box_meter,
    below_average: below_average_box_meter,
    poor: poor_box_meter,
  };
  //to make it easy in the prop to get the source meter
  //this is for the indexes
  //where the _4_meter_list represent indexes with 4 scores with 1-bad and 4- excellent
  //_3_meter_list represents indexes with 3 scores with 1-good and 3- bad
  // let _4_meter_list = [_4_score_bad_meter,_4_score_fair_meter,_4_score_good_meter,_4_score_excellent_meter];
  // let _3_meter_list = [_3_score_good_meter,_3_score_fair_meter,_3_score_bad_meter];

  // Change for P2 - scores 1 bad , 2 Fair, 3 Good
  let _3_meter_list = [
    _3_score_bad_meter,
    _3_score_fair_meter,
    _3_score_good_meter,
  ];

  function getYinSrcImg(score) {
    // the yin_score are opposite thus it is reverse of the array
    score = parseInt(score);
    if (score == 1) {
      score = 3;
    } else if (score == 3) {
      score = 1;
    }

    return _3_meter_list[score - 1];
  }

  function getVitalText(score) {
    let text;
    if (score == 1) {
      text = "Subpar";
    } else if (score == 2) {
      text = "Fair";
    } else {
      text = "Good";
    }
    return text;
  }

  function getHrSrcImg(score) {
    //heart rate optimum is between 60 - 100
    let hr_score = parseInt(score);
    let hr_value = "poor";

    if (hr_score >= 100) {
      hr_value = "poor";
    } else if (hr_score >= 85 && hr_score < 100) {
      hr_value = "average";
    } else {
      hr_value = "excellent";
    }

    // how to know the others?

    return box_meter[hr_value];
  }
  function getBrSrcImg(score) {
    // let br_score = parseInt(score);
    let br_value = "poor";

    if (score >= 12 && score < 25) {
      br_value = "average";
    } else if (score >= 25) {
      br_value = "poor";
    } else {
      br_value = "excellent";
    }

    return box_meter[br_value];
  }
  function getBsSrcImg(score) {
    let bs_score = Math.round(score / 18);
    let bs_value = "poor";

    if (bs_score >= 10) {
      bs_value = "poor";
    } else if (bs_score >= 8 && bs_score < 10) {
      bs_value = "average";
    } else {
      bs_value = "excellent";
    }

    return box_meter[bs_value];
  }

  function getSpSrcImg(score) {
    //spo2
    let sp_score = parseInt(score);
    let sp_value = "poor";

    if (sp_score <= 93) {
      sp_value = "poor";
    } else if (sp_score > 93 && sp_score <= 96) {
      sp_value = "average";
    } else {
      sp_value = "excellent";
    }

    return box_meter[sp_value];
  }
  function getCholSrcImg(score) {
    let chol_score = parseInt(score);
    let chol_value = "poor";

    if (chol_score >= 200) {
      chol_value = "poor";
    } else if (chol_score >= 170 && chol_score < 200) {
      chol_value = "average";
    } else {
      chol_value = "excellent";
    }

    return box_meter[chol_value];
  }
  function getBpSrcImg(syst_value, diast_value) {
    // let bp_arr = score.split("/");
    let bp_value = "poor";
    let sys_score = parseInt(syst_value); //systolic
    let dia_score = parseInt(diast_value); //diastolic

    if (sys_score > 129 && dia_score > 81) {
      bp_value = "poor";
    } else if (sys_score > 119 || dia_score > 75) {
      bp_value = "average";
    } else {
      bp_value = "excellent";
    }

    return box_meter[bp_value];
  }
  // function getSrcImage(score)
  // {
  //     if (score )
  //     return good_meter;
  // }
  const [active_idx, setActiveIdx] = React.useState(-1);

  // let stressTxt = ["Acute Stress", "Moderate Stress", "Low Stress"];
  // let fatigueTxt = ["Exhausted", "Tired", "Not Tired"];
  // let yinTxt = [
  //   "You are showing signs of weak yin !",
  //   "You are showing signs of fair yin balance !",
  //   "You are showing signs of good yin balance !",
  // ];
  // let yangTxt = [
  //   "You are showing signs of weak yang balance !",
  //   "You are showing signs of fair yang balance !",
  //   "You are showing signs of good yang balance !",
  // ];
  // let senseTxt = [
  //   "You are showing signs of being high anxiety sensitivity !",
  //   "You are showing signs of being moderate anxiety sensitivity !",
  //   "You have a healthy lifestyle",
  // ];
  // let sugarMetaTxt = [
  //   "You're showing higher glucose metabolism",
  //   "Your glucose metabolism is controlled !",
  //   "You have a healthy lifestyle !",
  // ];
  // let cholMetaTxt = [
  //   "Higher Cholesterol Levels",
  //   "Elevated Cholesterol Levels",
  //   "Healthy Cholesterol Levels",
  // ];
  // let o2MetaTxt = [
  //   "Lower Oxygen Levels",
  //   "Moderate Oxygen Levels",
  //   "Healthy Oxygen Levels",
  // ];
  // let circulationTxt = ["Lower circulation", "Medium-fair circulation", "Good circulation"];
  // let vitaDefTxt = [
  //   "Vitamin balance needs to be improved",
  //   "Moderate Vitamin Balance",
  //   "Optimal Vitamin Balance",
  // ];
  // let heiTxt = [
  //   "Nutrition needs to be improved",
  //   "Nourishing Progress",
  //   "Nutrition Adequate",
  // ];
  let stressTxt = ["High", "Moderate", "Low"];
  let fatigueTxt = ["High", "Moderate", "Low"];
  let yinTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let yangTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let senseTxt = ["High", "Moderate", "Low"];
  let sugarMetaTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let cholMetaTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let o2MetaTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let circulationTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let vitaDefTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];
  let heiTxt = [
    "Poor",
    "Moderate",
    "Good",
  ];

  return (
    <>
      <section className="signs-table">
        <table>
          <thead>
            <tr>
              <th>Test</th>
              <th>Results</th>
              <th>Normal range: Low - High</th>
              <th>Units</th>
              <th>Figure</th>
            </tr>
          </thead>
          <tr>
            <td colspan="5">
              <div className="mLeft title-span">Vital Signs</div>{" "}
            </td>
          </tr>
          <tbody>
            <VitalsRowData
              vital="Heart Rate"
              result={getVitalText(reportData.hrScore)}
              normal="60 - 100"
              img_src={getHrSrcImg(reportData.hrValue)}
              units="b.p.m"
            />
            <VitalsRowData
              vital="Breathing Rate"
              result={getVitalText(reportData.rrScore)}
              normal="12 - 16"
              img_src={getBrSrcImg(reportData.brValue)}
              units="b.p.m"
            />
            <VitalsRowData
              vital="Spo2 Rate"
              result={getVitalText(reportData.spo2Score)}
              normal="95% - 100%"
              img_src={getSpSrcImg(reportData.spo2Value)}
              units="%"
            />
            <VitalsRowData
              vital="Blood Pressure"
              result={getVitalText(reportData.bpScore)}
              normal="90/60 - 120/80"
              img_src={getBpSrcImg(reportData.systValue, reportData.diastValue)}
              units="mmHg"
            />
            <VitalsRowData
              vital="Blood Sugar"
              result={getVitalText(reportData.bsScore)}
              normal="60 - 140"
              img_src={getBsSrcImg(reportData.bsValue)}
              units="md/dL"
            />
            <VitalsRowData
              vital="Blood Cholesterol"
              result={getVitalText(reportData.bcScore)}
              normal="120 - 200"
              img_src={getCholSrcImg(reportData.cholValue)}
              units="mg/dl"
            />
          </tbody>
        </table>
      </section>

      <section className="flex flex-col">
        <div class="group flex relative w-full  pt-5">
          {/* <span class=" text-white mr-5">
            <i
              class="fa fa-info-circle text-blue-800 w-10"
              aria-hidden="true"
            ></i>
          </span>
          <div
            class="group-hover:opacity-100 transition-opacity bg-blue-900 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
    -translate-x-1/2 translate-y-1/2 opacity-0  mx-auto py-3"
          >
            Show the score level and result figures for each of the 11 indexes.
          </div> */}
          <Tooltip text="Show the score level and result figures for each of the 11 indexes." />
        </div>
        <table className="index-table">
          <tr>
            <td colspan="5">
              <div className="mSize title-span">Indexes</div>{" "}
            </td>
          </tr>
          <tbody>
            <IndexRowData
              indexName="Stress"
              score={reportData.stressScore}
              img_src={_3_meter_list[Math.round(reportData.stressScore) - 1]}
              short_desc={stressTxt[Math.round(reportData.stressScore) - 1]}
              show_more={active_idx === 0}
              onShow={() => setActiveIdx(active_idx == 0 ? -1 : 0)}
            >
              {/* <Description score_name="Stress Score" message={report_obj["stressRecom"]} radar_state={report_obj["stressRadar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Fatigue"
              score={reportData.fatigueScore}
              img_src={_3_meter_list[Math.round(reportData.fatigueScore) - 1]}
              short_desc={fatigueTxt[Math.round(reportData.fatigueScore) - 1]}
              show_more={active_idx === 1}
              onShow={() => setActiveIdx(active_idx == 1 ? -1 : 1)}
            >
              {/* <Description score_name="Fatigue Score" message={report_obj["fatigue_recom"]} radar_state={report_obj["fatigue_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Yin"
              score={reportData.yinScore}
              img_src={_3_meter_list[Math.round(reportData.yinScore) - 1]}
              short_desc={yinTxt[Math.round(reportData.yinScore) - 1]}
              show_more={active_idx === 2}
              onShow={() => setActiveIdx(active_idx == 2 ? -1 : 2)}
            >
              {/* <Description score_name="Yin Score" message={report_obj["yin_recom"]} radar_state={report_obj['yin_radar']} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Yang"
              score={reportData.yangScore}
              img_src={_3_meter_list[Math.round(reportData.yangScore) - 1]}
              short_desc={yangTxt[Math.round(reportData.yangScore) - 1]}
              show_more={active_idx === 3}
              onShow={() => setActiveIdx(active_idx == 3 ? -1 : 3)}
            >
              {/* <Description score_name="Yang Score" message={report_obj["yang_recom"]} radar_state={report_obj["yang_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Worry Feelings"
              score={reportData.sensitivityScore}
              img_src={
                _3_meter_list[Math.round(reportData.sensitivityScore) - 1]
              }
              short_desc={senseTxt[Math.round(reportData.sensitivityScore) - 1]}
              show_more={active_idx === 4}
              onShow={() => setActiveIdx(active_idx == 4 ? -1 : 4)}
            >
              {/* <Description score_name="Sensitivity Score" message={report_obj["sensitivity_recom"]} radar_state={report_obj["sensitivity_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Sugar Metabolism"
              score={reportData.glucoseMetabolismScore}
              img_src={
                _3_meter_list[Math.round(reportData.glucoseMetabolismScore) - 1]
              }
              short_desc={
                sugarMetaTxt[Math.round(reportData.glucoseMetabolismScore) - 1]
              }
              show_more={active_idx === 5}
              onShow={() => setActiveIdx(active_idx == 5 ? -1 : 5)}
            >
              {/* <Description score_name="Glucose Metabolism Score" message={report_obj["sug_met_recom"]} radar_state={report_obj['sug_met_rad']} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Cholesterol Metabolism"
              score={reportData.cholMetabolismScore}
              img_src={
                _3_meter_list[Math.round(reportData.cholMetabolismScore) - 1]
              }
              short_desc={
                cholMetaTxt[Math.round(reportData.cholMetabolismScore) - 1]
              }
              show_more={active_idx === 6}
              onShow={() => setActiveIdx(active_idx == 6 ? -1 : 6)}
            >
              {/* <Description score_name="Cholesterol Metabolism Score" message={report_obj["chol_met_recom"]} radar_state={report_obj["chol_met_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Oxygen Metabolism"
              score={reportData.o2MetabolismScore}
              img_src={
                _3_meter_list[Math.round(reportData.o2MetabolismScore) - 1]
              }
              short_desc={
                o2MetaTxt[Math.round(reportData.o2MetabolismScore) - 1]
              }
              show_more={active_idx === 7}
              onShow={() => setActiveIdx(active_idx == 7 ? -1 : 7)}
            >
              {/* <Description score_name="Oxygen Metabolism Score" message={report_obj["oxygen_met_recom"]} radar_state={report_obj["oxygen_met_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Circulation"
              score={reportData.circulationScore}
              img_src={
                _3_meter_list[Math.round(reportData.circulationScore) - 1]
              }
              short_desc={
                circulationTxt[Math.round(reportData.circulationScore) - 1]
              }
              show_more={active_idx === 8}
              onShow={() => setActiveIdx(active_idx == 8 ? -1 : 8)}
            >
              {/* <Description score_name="Circulation Score" message={report_obj["circulation_recom"]} radar_state={report_obj["circulation_radar"]} /> */}
            </IndexRowData>

            <IndexRowData
              indexName="Healthy Eating Index (H.E.I)"
              score={reportData.dietScore}
              img_src={_3_meter_list[Math.round(reportData.dietScore) - 1]}
              short_desc={heiTxt[Math.round(reportData.dietScore) - 1]}
              show_more={active_idx === 9}
              onShow={() => setActiveIdx(active_idx == 9 ? -1 : 9)}
            >
              {/* <Description score_name="H.E.I" message={report_obj["hei_recom"]} radar_state={report_obj["hei_radar"]} />                        */}
            </IndexRowData>

            <IndexRowData
              indexName="Vitamins"
              score={reportData.vitaminScore}
              img_src={_3_meter_list[Math.round(reportData.vitaminScore) - 1]}
              short_desc={vitaDefTxt[Math.round(reportData.vitaminScore) - 1]}
              show_more={active_idx === 10}
              onShow={() => setActiveIdx(active_idx == 10 ? -1 : 10)}
            >
              {/* <Description score_name="Vitamin Deficiency Score" message={report_obj["vit_recom"]} radar_state={report_obj['vit_radar']} />                          */}
            </IndexRowData>
          </tbody>
        </table>
      </section>
    </>
  );
}

export default VitalsReport;
