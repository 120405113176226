import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/v2/Magnesium.png'
import VitalIcon from '../../../assets/wellness/v2/Calcium.png'
import RecIcon from '../../../assets/wellness/v2/Iron.png'
import PIcon from '../../../assets/wellness/v2/Phosphorous.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import { Link } from 'react-router-dom';
import Tooltip from '../../common/Tooltip';
import LeftSection from '../../common/LeftSection';
const color='orange-900'
const MineralsDash = () => {
  const [schartData,setSChartData]=useState([]);
  const [fchartData,setFChartData]=useState([]);
  const [bchartData,setBChartData]=useState([]);
  const [ichartData,setIChartData]=useState([]);
  const setDefaultChartData = async()=>{
      let sresp = await fetchWeeklyData(34,'Magnesium');
      let fresp = await fetchWeeklyData(35,'Calcium');
      let bresp = await fetchWeeklyData(36,'Iron');
      let iresp = await fetchWeeklyData(37,'Phosphorous');
      if (bresp[0] !== 0){
          setSChartData(sresp);
          setFChartData(fresp);
          setBChartData(bresp);
          setIChartData(iresp);
      }
  }    
  useEffect(()=>{
      setDefaultChartData();
  },[])
    return (
      // <Layout>

      <div
        className="min-h-screen1 w-full flex flex-col md:flex-row pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >

        <div className='grow md:w-3/4'>

        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Magnesium
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Magnesium with the average. Click the right arrow to check the detail.



"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={schartData} />
            </div>
            <a
              href="/magnesium"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>

            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Calcium
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Calcium with the average. Click the right arrow to check the detail.



"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={fchartData} />
            </div>

            <Link
              to="/calcium"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </Link>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Iron
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Iron with the average. Click the right arrow to check the detail.



"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a href="/iron" className="text-2xl my-auto hover:bg-gray-300 p-3">
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={PIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Phosphorous
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Phosphorous with the average. Click the right arrow to check the detail.



"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={ichartData} />
            </div>
            <a
              href="/phosphorous"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        </div>
        <div className='flex'>
          <LeftSection />
        </div>
      </div>

      // </Layout>
    );
}
 
export default MineralsDash;