import React, { useEffect, useState } from "react";
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from "../../../assets/Healthiness/Icon basic vitals.png";
import VitalIcon from "../../../assets/Healthiness/Icon core vitals.png";
import RecIcon from "../../../assets/Healthiness/Healthiness recomendation.png";
import bg from "../../../assets/Healthiness/Summary.jpg";
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from "../../firebase/scans";
import LeftSection from "../../common/LeftSection";

const WellnessDash = () => {
  const [bchartData, setBChartData] = useState([]);
  const [cchartData, setCChartData] = useState([]);
  const [hchartData, setHChartData] = useState([]);
  const setDefaultChartData = async () => {
    let bresp = await fetchWeeklyData(11, "Fitness");
    let cresp = await fetchWeeklyData(20, "Eastern Medicine");
    let hresp = await fetchWeeklyData(24, "Diet");
    if (bresp[0] !== 0) {
      setBChartData(bresp);
      setCChartData(cresp);
      setHChartData(hresp);
    }
  };
  useEffect(() => {
    setDefaultChartData();
  }, []);
  return (
    // <Layout>

    <div
      className="h-screen1 w-full flex flex-col md:flex-row pl-3"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSized: "cover",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="grow">

      <div className="flex flex-col md:flex-row  pt-1 w-full">
        <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
          <div>
            <img src={RecIcon} className="h-12" />
          </div>
          <div className=" my-auto ml-3 text-teal-400 font-bold">Diet</div>
        </div>
        <div className="flex flex-row w-3/4">
          <div className="md:w-1/2">
            <MyChart2 data={hchartData} />
          </div>
          <a
            href="/dietdashboard"
            className="text-2xl my-auto hover:bg-gray-300 p-3"
          >
            {">"}
          </a>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pt-1">
        <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
          <div>
            <img src={BasicIcon} className="h-12" />
          </div>
          <div className=" my-auto ml-3 text-green-500 font-bold">Fitness</div>
        </div>
        <div className="flex flex-row w-3/4">
          <div className="md:w-1/2">
            <MyChart2 data={bchartData} />
          </div>
          <a
            href="/fitnessdash"
            className="text-2xl my-auto hover:bg-gray-300 p-3"
          >
            {">"}
          </a>
        </div>
      </div>
      <div className="flex flex-col md:flex-row pt-1 mb-1">
        <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
          <div>
            <img src={VitalIcon} className="h-12" />
          </div>
          <div className=" my-auto ml-3 text-blue-500 font-bold">
            Eastern Medicine
          </div>
        </div>
        <div className="flex flex-row w-3/4">
          <div className="md:w-1/2">
            <MyChart2 data={cchartData} />
          </div>
          <a
            href="/easternmedicine"
            className="text-2xl my-auto hover:bg-gray-300 p-3"
          >
            {">"}
          </a>
        </div>
      </div>
      </div>
      <div className="flex">
        <LeftSection />
      </div>
    </div>

    // </Layout>
  );
};

export default WellnessDash;
