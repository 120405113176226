const makoUsername = "DocsunAPIAccess"
const makoPassword = "dgnF6z4ADGLUSWwn"
const companyID = 270;
const instanceType = 3;
const apiURL = 'https://api.teqtank.com'

export const getJWTToken = async()=>{
    try {
        const response = await fetch(`${apiURL}/Authorize/CompanyId/${companyID}/${instanceType}`,
        {
            method:'GET',
            headers:{
                'Accept':'application/json',
                'MakoUsername':makoUsername,
                'MakoPassword':makoPassword
            }
        });
        const data  = await response.json();
        if(response.ok){
            localStorage.setItem('token',data.data.token) // valid for 2 hours
            return true
        }else{
            localStorage.clear()
            return false
        }
    } catch (e) {
        localStorage.clear()
        return false
    }
}

export const getCustomerId = async(ssoKey)=>{
    try {
        const response = await fetch(`${apiURL}/Authorize/DecryptSSOKey?ssokey=${ssoKey}`,{
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Authorization':`Bearer ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json()
        if (response.ok){// the customer id retruned from data.data is an int. 
            localStorage.setItem('customerID',data.data)
            localStorage.setItem('status','true')
            return true
        }else{
            localStorage.clear()
            return false
        }
    } catch (e) {
        localStorage.clear()
        return false
    }
}

export const getCustomerInfo = async()=>{
    try {
        const response = await fetch(`${apiURL}/Crm/Customers/${localStorage.getItem('customerID')}`,{
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Authorization':`Bearer ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json()
        if (response.ok){
            return {
                status:true,
                profileData:data.data.publicProfile,
                birthdate:data.data.birthDate
            }
        }else{
            return {
                status:false
            }
        }
    } catch (e) {
        return {
            status:false
        } 
    }
}

export const getSubscriptionInfo = async()=>{
    try {
        const response = await fetch(`${apiURL}/Subscription/${localStorage.getItem('customerID')}`,{
            method:'GET',
            headers:{
                'Accept':'application/json',
                'Authorization':`Bearer ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json()
        console.log('Subscription Info resp : ',response)
        console.log('subscription info data : ',data)
        if (response.ok){
            return {
                status: true,
                subscriptionStatus:data.data.activeSubscription,//boolean
                subscriptionInfo:data.data
            }
        }else{
            return {
                status:false
            }
        }  
    } catch (e) {
        // localStorage.clear()
        return {
            status:false
        }   
    }
    
}