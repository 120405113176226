import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/v2/Glucose.png'
import VitalIcon from '../../../assets/wellness/v2/Cholesterol.png'
import RecIcon from '../../../assets/wellness/v2/Oxygen.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import LeftSection from '../../common/LeftSection';
import Tooltip from '../../common/Tooltip';

const MetabolismDash = () => {
    const [bchartData,setBChartData]=useState([]);
    const [cchartData,setCChartData]=useState([]);
    const [hchartData,setHChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let bresp = await fetchWeeklyData(17,'Glucose Metabolism');
        let cresp = await fetchWeeklyData(18,'Cholesterol Metabolism');
        let hresp = await fetchWeeklyData(19,'Oxygen Metabolism');
        if (bresp[0] !== 0){
            setBChartData(bresp);
            setCChartData(cresp);
            setHChartData(hresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    const color ='orange-600'
    return (
      <div
        className="h-screen1 w-full flex flex-col md:flex-row pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >
        <div className="grow md:w-3/4">
          <div className="flex flex-col md:flex-row  pt-1 w-full">
            <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
              <div>
                <img src={BasicIcon} className="h-12" />
              </div>
              <div className="flex flex-col">
                <div className={` my-auto ml-3 text-${color} font-bold`}>
                  Glucose Metabolism
                </div>
                <div>
                  <Tooltip
                    text="This shows the 7-day trending of glucose with the average. Click the right arrow to check the detail.

"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row md:w-3/4">
              <div className="md:w-1/2">
                <MyChart2 data={bchartData} />
              </div>
              <a
                href="/glucosemetabolism"
                className="text-2xl my-auto hover:bg-gray-300 p-3"
              >
                {">"}
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row pt-1">
            <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
              <div>
                <img src={VitalIcon} className="h-12" />
              </div>
              <div className="flex flex-col">
                <div className={` my-auto ml-3 text-${color} font-bold`}>
                  Cholesterol Metabolism
                </div>
                <div>
                  <Tooltip
                    text="This shows the 7-day trending of Cholesterol with the average. Click the right arrow to check the detail.

"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row w-3/4">
              <div className="md:w-1/2">
                <MyChart2 data={cchartData} />
              </div>
              <a
                href="/cholesterolmetabolism"
                className="text-2xl my-auto hover:bg-gray-300 p-3"
              >
                {">"}
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row pt-1 mb-2">
            <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
              <div>
                <img src={RecIcon} className="h-12" />
              </div>
              <div className="flex flex-col">
                <div className={` my-auto ml-3 text-${color} font-bold`}>
                  Oxygen Metabolism
                </div>
                <div>
                  <Tooltip
                    text="This shows the 7-day trending of oxygen with the average. Click the right arrow to check the detail.

"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row md:w-3/4">
              <div className="md:w-1/2">
                <MyChart2 data={hchartData} />
              </div>
              <a
                href="/o2metabolism"
                className="text-2xl my-auto hover:bg-gray-300 p-3"
              >
                {">"}
              </a>
            </div>
          </div>
        </div>
        <div className='flex'>
          <LeftSection />
        </div>
      </div>
    );
}
 
export default MetabolismDash;