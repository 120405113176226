import { auth,db } from "./main";
// import {
//     getAuth,
//     sendPasswordResetEmail,
//     signOut,
//     updatePassword,
// } from "firebase/auth";
// import {
//     createUserWithEmailAndPassword,
//     sendEmailVerification,
//     signInWithEmailAndPassword,
//     onAuthStateChanged,
// } from "firebase/auth";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
// import {Buffer} from "buffer";
import moment from "moment-timezone";
import { getCustomerInfo, getSubscriptionInfo } from "./ttapi";
import { onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";

function calculateAge(birthYear) {
    let currentYear = new Date().getFullYear();
    return currentYear - birthYear;
}
function calculateBirthYear(age) {
  if(age !== null ){
    const currentYear = new Date().getFullYear();
    const birthYear = currentYear - age;
    return birthYear;
  }else{
    return null;
  }
}

export const registerCustomerWithFirebase = async (
  ) => {
    let respStatus = false;
    let registeredStatus = await new Promise((resolve,reject)=>{
      onAuthStateChanged(auth,async(user)=>{
        if(user){
          let customerID = localStorage.getItem('customerID')
          let customerInfo = await getCustomerInfo();
          let subscriptionInfo = await getSubscriptionInfo();
          if (customerInfo.status && subscriptionInfo.status){
            let dateString = new Date(customerInfo.birthdate);
            let birthYear = dateString.getFullYear();
            let cusAge = calculateAge(birthYear);
            await setDoc(doc(db, "users", customerID), {
              customerID:customerID,
              firstName: customerInfo.profileData.firstName,
              lastName: customerInfo.profileData.lastName,
              email: customerInfo.profileData.email,
              publicProfile:customerInfo.profileData,
              subscription: subscriptionInfo.subscriptionStatus,
              subscriptionDetails:subscriptionInfo.subscriptionInfo,
              total_scans: 0,
              goal:false, 
              resultDoc:"",
              prevDoc:"",
              scanDate:"",
              scanTime:"",
              age:cusAge,
              gender:null,
              height:null,
              weight:null,
              weeklyDoc:''
            })
            respStatus=true
          }
        }
        resolve(respStatus)
      })
    })
    respStatus=registeredStatus;
    return respStatus
};

const getTZ = ()=>{
  let tz = moment.tz.guess()
  localStorage.setItem('tz',tz)
}

export const signInUsingCustomerIDWithFirebase = async () => {
  let respStatus = {
    status:false,
    message:"User does not exist !",
    subscription:false
  }
  let signInStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let customerID = localStorage.getItem('customerID')
        const docRef = doc(db, "users", customerID);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          getTZ()
          respStatus =  {
            status:true,
            message:`Welcome ${docSnap.data().firstName} ${docSnap.data().lastName} !`,
            subscription:docSnap.data().subscription
          }
        } else {
          respStatus =  {
            status:false,
            message:"User does not exist !",
            subscription:false
          }
        }
      }
      resolve(respStatus)
    })
  })
  respStatus=signInStatus
  return respStatus
};

export const signInAnnonmousWithFirebase = async()=>{
  let resp = await signInAnonymously(auth).then(()=>{
    return true
  }).catch((e)=>{
    return false
  })
  return resp
}
  
export const logOutWithFirebase = async () => {
  await signOut(auth).then(()=>{
    return true;
  }).catch(()=>{
    return false;
  })
};

export const fetchUserInfo = async()=>{
  let respObj = {
    status:false
  }
  let fetchUserStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let checkAuth = await getSubscriptionInfo();
        if (checkAuth.status){
          let customerID = localStorage.getItem('customerID')
          const docRef = doc(db,"users",customerID);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            let birthYear = null
            if (docSnap.data().age !== null){
              birthYear = calculateBirthYear(docSnap.data().age)
            }
            respObj = {
              status:true,
              firstName: docSnap.data().firstName,
              lastName:docSnap.data().lastName,
              email:docSnap.data().email,
              subscription:docSnap.data().subscription,
              subscriptionDetails:docSnap.data().subscriptionDetails,
              publicProfile:docSnap.data().publicProfile,
              scanDate:docSnap.data().scanDate,
              height:docSnap.data().height,
              weight:docSnap.data().weight,
              gender:docSnap.data().gender,
              birthYear:birthYear,
              goal:docSnap.data().goal
            }
          }else{
            respObj = {
              status:false
            }
          }
        }
      }
      resolve(respObj)
    })
  })
  respObj=fetchUserStatus
  return respObj
}

export const updateUserInfo = async(
  setGoal,
  height,weight,gender)=>{
  let respStatus=false;
  let updateStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let checkAuth = await getSubscriptionInfo();
        if (checkAuth.status){
          let customerID = localStorage.getItem('customerID')
          const docRef = doc(db,"users",customerID);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            if (setGoal){
              await updateDoc(doc(db, "users",customerID), {
                height:height,
                weight:weight,
                gender:gender,
                goal:setGoal
              }).then(()=>{
                respStatus= true;
              }).catch(()=>{
                respStatus=false;
              })
            }else{
              respStatus=false;
            }
          }else{
            respStatus=false;
          }
        }else{
          respStatus=false;
        }
      }
      resolve(respStatus)
    })
  })
  respStatus=updateStatus
  return respStatus
}

export const subStateListner = async()=>{
  let subStatus = false;
  let respStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let checkAuth = await getSubscriptionInfo();
        if (checkAuth.status){
          subStatus =  true
        }else{
          subStatus =  false
        }
      }
      resolve(subStatus)
    })
  })
  subStatus=respStatus
  return subStatus
}

export const scanRecordsListner = async()=>{
  let scanStatus =false;
  let respStatus = await new Promise((resolve,reject)=>{
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        let checkAuth = await getSubscriptionInfo();
        if (checkAuth.status){
          let customerID = localStorage.getItem('customerID')
          const docRef = doc(db,"users",customerID);
          const docSnap = await getDoc(docRef);
          if(docSnap.exists()){
            if(docSnap.data().weeklyDoc != ''){
              scanStatus = true
            }else{
              scanStatus = false
            }
          }
        }
      }
      resolve(scanStatus)
    })
  })
  scanStatus=respStatus
  return scanStatus
}