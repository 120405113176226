import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Icon from "./assets/Scan.png";
import SCAN from "./assets/nav/How to scan.png";
import ModalCtx from "./components/store/modalcontext";

const Footer = () => {
  const ctnx = useContext(ModalCtx);
  return (
    <>
      {/* <footer class="fixed bottom-0 left-0 z-20 w-full p-1 pt-0 shadow md:flex md:items-center md:justify-between md:p-1 flex-row opacity-100 "> */}
      <footer class="fixed bottom-0 left-0 z-20 w-3/4 p-1 pt-0 shadow  md:p-1 flex-row opacity-100 ">
        <div class="flex flex-col items-center text-sm text-gray-500 text-center opacity-100 w-1/5 pl-4 justify-center">
          <div className="flex">
            <button
              onClick={() => ctnx.toggleIsOpen()}
              class="relative inline-flex items-center justify-center  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group   hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 "
            >
              <img src={SCAN} c className="h-12" />
            </button>
          </div>
          <div className="flex">
            <a
              href="/scan"
              class="relative inline-flex items-center justify-center  mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group group-hover:via-red-300 group-hover:to-yellow-200 focus:ring-4 focus:outline-none focus:ring-red-100"
            >
              <span class="relative px-20   transition-all ease-in duration-75  rounded-md group-hover:bg-opacity-0">
                <img src={Icon} className="h-10" />
              </span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
