import React, { useEffect, useState } from "react";
import Layout from "../layout";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import First from "./first";
// import bg from "../../assets/01.jpg";
import bg from "../../../assets/Healthiness/Summary.jpg";
import DashChart from "./dashchart";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  fetchLatestMainScore,
  fetchWeeklyMainData,
} from "../../firebase/scans";
import SubModal from "../profile/submodal";
import LeftSection from "../../common/LeftSection";
import Tooltip from "../../common/Tooltip";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const Dashboard = () => {
  const [hRatio, setHRatio] = useState("");
  const [wRatio, setWRatio] = useState("");
  const [sRatio, setSRatio] = useState("");
  const [hPercent, setHPercent] = useState(null);
  const [wPercent, setWPercent] = useState(null);
  const [sPercent, setSPercent] = useState(null);
  const [hText, setHText] = useState("");
  const [wText, setWText] = useState("");
  const [sText, setSText] = useState("");
  const [chartData, setChartData] = useState([]);

  const setLatestValues = async () => {
    let resp = await fetchLatestMainScore();
    if (resp.hScore !== -1) {
      setHRatio(Math.round(resp.hScore));
      setWRatio(Math.round(resp.wScore));
      setSRatio(Math.round(resp.sScore));
      setHPercent(resp.hPercent);
      setWPercent(resp.wPercent);
      setSPercent(resp.sPercent);
      setHText(resp.hText);
      setWText(resp.wText);
      setSText(resp.sText);
    } else {
      setHRatio("");
      setWRatio("");
      setSRatio("");
      setHPercent(0);
      setWPercent(0);
      setSPercent(0);
      setHText("");
      setWText("");
      setSText("");
    }
  };

  const setGraphData = async () => {
    let resp = await fetchWeeklyMainData();
    setChartData(resp);
  };

  useEffect(() => {
    setLatestValues();
    setGraphData();
  }, []);

  return (
    <div
      className="h-full w-full flex flex-col md:flex-row"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      {/* <SubModal /> */}

      <div className="grow">
        <div className="flex flex-row p-3  mx-auto w-full">
          <div className="mx-auto flex flex-row space-x-3">
            <div className="rounded-lg  bg-white flex flex-col ">
              <div className="mx-2">Healthness Score</div>
              {/* <div className="radial-progress text-blue-500" style={{"--value":50}}>Fair</div> */}
              <div style={{ width: 100, height: 100 }} className="mx-auto">
                <CircularProgressbar value={hPercent} text={hText} />
              </div>
              <div className="mx-3 bg-red-500 text-white bottom-0 rounded-lg mt-1 ">
                <div className="mx-auto w-full">
                  <div className="mx-auto">
                    <center>{hRatio}/130</center>
                  </div>
                </div>
              </div>

              <Tooltip
                text='Show the score ring with the level of healthiness. The number
                  is in the format of "current / total" score'
              />
            </div>
            <div className="rounded-lg  bg-white flex flex-col">
              <div className="mx-2">Wellness Score</div>
              <div style={{ width: 100, height: 100 }} className="mx-auto">
                <CircularProgressbar value={wPercent} text={wText} />
              </div>
              <div className="mx-3 bg-red-500 text-white bottom-0 rounded-lg mt-1">
                <center>{wRatio}/80</center>
              </div>
              <div class="group  relative text-end justify-items-end">
                
                <Tooltip
                  text='Show the score ring with the level of wellness. The number is
                  in the format of "current / total" score'
                />
              </div>
            </div>
            <div className="rounded-lg  bg-white flex flex-col">
              <div className="mx-2">Skin Health Score</div>
              <div style={{ width: 100, height: 100 }} className="mx-auto">
                <CircularProgressbar value={sPercent} text={sText} />
              </div>
              <div className="mx-3 bg-red-500 text-white bottom-0 rounded-lg mt-1">
                <center>{sRatio}/30</center>
              </div>
              <div class="group  relative text-end justify-items-end">
                
                <Tooltip text='Show the score ring with the level of skin health. The number
                  is in the format of "current / total" score' />
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 mx-auto">
          <DashChart data={chartData} />
          <div class="group  relative text-end justify-items-end">
            
            <Tooltip text='This graph shows the weekly trending of all three major
              categories: healthiness, wellness, and skin health.' />
          </div>
        </div>
      </div>
      <div className="flex">
        <LeftSection />
      </div>
    </div>
  );
};

export default Dashboard;
