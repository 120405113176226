import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Circular = () => {
    return ( 
        <div style={{ width: 100, height: 100 }} className="mx-auto">
            <CircularProgressbar value={66} text={`Fair`} />
          </div>
                   
     );
}
 
export default Circular;