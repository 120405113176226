import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/Icoon Vitamin.png'
import VitalIcon from '../../../assets/wellness/Minerals.png'
import RecIcon from '../../../assets/wellness/Nutrients.png'
import bg from '../../../assets/Healthiness/Summary.jpg'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import Tooltip from '../../common/Tooltip';
import LeftSection from '../../common/LeftSection';

const DietDash = () => {
    const [bchartData,setBChartData]=useState([]);
    const [cchartData,setCChartData]=useState([]);
    const [hchartData,setHChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let bresp = await fetchWeeklyData(25,'Vitamin');
        let cresp = await fetchWeeklyData(33,'Minerals');
        let hresp = await fetchWeeklyData(38,'Nutrients');
        if (bresp[0] !== 0){
            setBChartData(bresp);
            setCChartData(cresp);
            setHChartData(hresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    return (
      // <Layout>

      <div
        className="min-h-screen1 w-full flex flex-col md:flex-row pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >

        <div className='grow w-3/4'>
        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Vitamin
              </div>

              <div>
                <Tooltip
                  text="This summarizes the 7-day trending of the subcategories, including Vitamin B, Vitamin C, Vitamin D, and Vitamin E.

"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a
              href="/vitamindashboard"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Minerals
              </div>

              <div>
                <Tooltip
                  text="This summarizes the 7-day trending of the subcategories, including Magnesium, Calcium, Iron, and Phosphorus.


"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={cchartData} />
            </div>
            <a
              href="/mineralsdash"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className=" my-auto ml-3 text-orange-400 font-bold">
                Nutrients
              </div>

              <div>
                <Tooltip
                  text="This summarizes the 7-day trending of the subcategories, including Probiotics, L-Theanine, Policosanol, Omega 3, and Red Yeast Rice.


"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={hchartData} />
            </div>
            <a
              href="/nutrientsdash"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        </div>
        <div className='flex'>

          <LeftSection />
        </div>

      </div>

      // </Layout>
    );
}
 
export default DietDash;