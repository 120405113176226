import Logo from "./assets/play_logo.png";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fetchUserInfo, logOutWithFirebase } from "./components/firebase/auth";
import { fetchDataForNav } from "./components/firebase/scans";
import Circle from "./components/main/circle";
import Tooltip from "./components/common/Tooltip";
const Nav = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [navData, setNavData] = useState({});
  const [summary, setSummary] = useState();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    // do something here
    logOutWithFirebase();
    localStorage.clear();
    // navigate("/");
    window.location.href = "/";
  };
  const setDataForNav = async () => {
    let resp = await fetchDataForNav();
    if (resp.status) {
      setNavData(resp.data[0]);
      setSummary(resp.summary);
    } else {
      setNavData({});
      setSummary(resp.summary);
    }
  };
  const setUserInfo = async () => {
    let respObj = await fetchUserInfo();
    if (respObj.status) {
      setName(`${respObj.firstName} ${respObj.lastName}`);
      setEmail(respObj.email);
    } else {
      setName("");
      setEmail("");
    }
  };
  useEffect(() => {
    setUserInfo();
    setDataForNav();
  }, []);
  return (
    <div>
      <nav class="fixed top-0 z-50 w-full bg-white border-b border-gray-200 ">
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="#" class="flex ml-2 md:mr-24">
                <img src={Logo} class="h-16 mr-3" alt="FlowBite Logo" />
                {/* <span class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                  HMS
                </span> */}
              </a>
            </div>
            <div class="flex items-center">
              <div class="flex items-center ml-3">
                <div>
                  <button
                    type="button"
                    class="flex text-sm bg-white rounded-full focus:ring-4 focus:ring-gray-300 "
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span class="sr-only">Open user menu</span>
                    {/* <img
                      class="w-8 h-8 rounded-full"
                      src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                      alt="user photo"
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span className="my-auto bg-white text-blue-500"></span>
                  </button>
                </div>
                <div
                  class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow "
                  id="dropdown-user"
                >
                  <div class="px-4 py-3" role="none">
                    <p class="text-sm text-gray-900 " role="none">
                      {name}
                    </p>
                    <p
                      class="text-sm font-medium text-gray-900 truncate "
                      role="none"
                    >
                      {email}
                    </p>
                  </div>
                  <ul class="py-1" role="none">
                    <li>
                      <a
                        href="/profile"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Profile
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="/subscription"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Subscription
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Settings
                      </a>
                    </li> */}

                    <li>
                      <a
                        // href="/"
                        onClick={handleLogOut}
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        class="  fixed top-0 left-0 z-40 w-64  pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0  h-3/5 mt-6 example"
        aria-label="Sidebar"
      >
        {/* <div className=" text-end">
          <div class="group flex relative w-full justify-end my-auto">
            <span class=" text-white mr-5">
              <i
                class="fa fa-info-circle text-blue-800 w-10"
                aria-hidden="true"
              ></i>
            </span>
            <div
              class="group-hover:opacity-100 transition-opacity bg-blue-400 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
     opacity-0  mx-auto"
            >
              The score ring beside each menu item is the summarized score of
              the sub-items within the category. You can click the menu to dive
              in for the detail of each category.
            </div>
          </div>
        </div> */}
        <Tooltip
          text="The score ring beside each menu item is the summarized score of
              the sub-items within the category. You can click the menu to dive
              in for the detail of each category."
        />
        <div class="h-full px-0 pb-0 overflow-y-auto bg-white  example ">
          <ul class="space-y-0 text-white">
            <li className="bg-[#2E4D92]">
              <Link
                to="/dashboard"
                class="flex items-center p-2 text-base font-bold text-white rounded-lg hover:bg-gray-100 hover:text-black "
              >
                {/* <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75   group-hover:text-gray-900  " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg> */}
                <Circle score={summary} />
                <span class="ml-0">Dashboard</span>
              </Link>
            </li>

            <li className="">
              <Link
                to={"/healthnessdashboard"}
                className="bg-[#D22927] hover:bg-gray-100"
              >
                <button
                  type="button"
                  class="bg-[#D22927] flex items-center w-full p-2 text-base font-bold text-white transition duration-75 group  hover:bg-gray-100 hover:text-black   "
                  aria-controls="dropdown-example"
                  data-collapse-toggle="dropdown-example"
                >
                  {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900    " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                  <Circle
                    score={
                      navData.healthnessScore ? navData.healthnessScore : 0
                    }
                  />
                  <span
                    class="flex-1 ml-0 text-left whitespace-nowrap"
                    sidebar-toggle-item
                  >
                    Healthness
                  </span>
                  <svg
                    sidebar-toggle-item
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </Link>
              <ul id="dropdown-example" class="py-0 space-y-0">
                {/* 
                  Start */}
                <li className=" bg-[#F54B49]">
                  <Link to={"/basicvitals"}>
                    <button
                      type="button"
                      class=" ml-3 flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg group  hover:bg-gray-100 hover:text-black    "
                      aria-controls="dropdown-example1"
                      data-collapse-toggle="dropdown-example1"
                    >
                      {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover: text-white    " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                      <Circle
                        score={navData.basicScore ? navData.basicScore : 0}
                      />{" "}
                      <span
                        class="flex-1 ml-0 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        Basic Vitals
                        <div className="flex flex-row">
                          <div></div>
                        </div>
                      </span>
                      <svg
                        sidebar-toggle-item
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                  <ul id="dropdown-example1" class=" py-2 space-y-2">
                    <li>
                      <Link
                        to="/basicvitals/heart-rate"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-black  "
                      >
                        <Circle score={navData.hrScore ? navData.hrScore : 0} />{" "}
                        Heart Rate
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="basicvitals/breathing-rate"
                        class="flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg pl-11 group  hover:bg-gray-100 hover:text-black    "
                      >
                        <Circle score={navData.rrScore ? navData.rrScore : 0} />{" "}
                        Breathing Rate
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/basicvitals/spo2"
                        class="flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg pl-11 group   hover:bg-gray-100 hover:text-black   "
                      >
                        <Circle
                          score={navData.spo2Score ? navData.spo2Score : 0}
                        />{" "}
                        SpO2
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className=" bg-[#F54B49]">
                  <Link to={"/corevitals"}>
                    <button
                      type="button"
                      class=" ml-3 flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg group   hover:bg-gray-100 hover:text-black   "
                      aria-controls="core-vitals"
                      data-collapse-toggle="core-vitals"
                    >
                      {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover: text-white    " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                      <Circle
                        score={navData.coreScore ? navData.coreScore : 0}
                      />
                      <span
                        class="flex-1 ml-0 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        Core Vitals
                      </span>
                      <svg
                        sidebar-toggle-item
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                  <ul id="core-vitals" class=" py-2 space-y-2">
                    <li>
                      <Link
                        to="/corevitals/bloodpressure"
                        class="flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg pl-11 group   hover:bg-gray-100 hover:text-black   "
                      >
                        <Circle score={navData.bpScore ? navData.bpScore : 0} />{" "}
                        Blood Pressure
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/corevitals/bloodsugar"
                        class="flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-black     "
                      >
                        <Circle score={navData.bsScore ? navData.bsScore : 0} />{" "}
                        Bood Sugar
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/corevitals/cholestoral"
                        class="flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg pl-11 group    hover:bg-gray-100 hover:text-black  "
                      >
                        <Circle score={navData.bcScore ? navData.bcScore : 0} />{" "}
                        Cholesterol
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* 
                  End */}
                <li className=" bg-[#F54B49]">
                  <Link
                    to="/healthtips"
                    class=" ml-3 flex items-center p-2 text-base font-normal  text-white rounded-lg  hover:bg-gray-100 hover:text-black    "
                  >
                    {/* <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75   group-hover: text-white  " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg> */}
                    <Circle
                      score={
                        navData.healthTipScore ? navData.healthTipScore : 0
                      }
                    />
                    <span class="ml-0">Health tips</span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* Start of Wellness */}
            <li className="bg-[#F491DE]">
              <Link to={"/wellness"} className="bg-orange-600">
                <button
                  type="button"
                  class="flex items-center w-full p-2 text-base font-bold text-white transition duration-75 rounded-lg group hover:bg-gray-100  hover:bg-gray-100 hover:text-black "
                  aria-controls="dropdown-2"
                  data-collapse-toggle="dropdown-2"
                >
                  <Circle
                    score={navData.wellnessScore ? navData.wellnessScore : 0}
                  />
                  <span
                    class="flex-1 ml-0 text-left whitespace-nowrap"
                    sidebar-toggle-item
                  >
                    Wellness
                  </span>
                  <svg
                    sidebar-toggle-item
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </Link>
              <ul id="dropdown-2" class=" py-0 space-y-0 bg-[#f7a6e5]">
                {/* Start 1 */}
                <li className="ml-3">
                  <Link to={"/dietdashboard"}>
                    <button
                      type="button"
                      class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100 hover:text-black   "
                      aria-controls="dropdown-21"
                      data-collapse-toggle="dropdown-21"
                    >
                      <Circle
                        score={navData.dietScore ? navData.dietScore : 0}
                      />

                      <span
                        class="flex-1 ml-0 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        Diet
                      </span>
                      <svg
                        sidebar-toggle-item
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                  <ul id="dropdown-21" class=" py-2 space-y-2">
                    {/* Start inner */}
                    <li className="ml-3">
                      <Link to={"/vitamindashboard"}>
                        <button
                          type="button"
                          class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100  "
                          aria-controls="dropdown-diet"
                          data-collapse-toggle="dropdown-diet"
                        >
                          <Circle
                            score={
                              navData.vitaminScore ? navData.vitaminScore : 0
                            }
                          />
                          <span
                            class="flex-1 ml-0 text-left whitespace-nowrap"
                            sidebar-toggle-item
                          >
                            Vitamin
                          </span>
                          <svg
                            sidebar-toggle-item
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                      <ul id="dropdown-diet" class=" py-2 space-y-2">
                        {/* Start Vitamin B */}
                        <li className="ml-9">
                          <Link to={"/vitaminbdashboard"}>
                            <button
                              type="button"
                              class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100  "
                              aria-controls="vitaminb"
                              data-collapse-toggle="vitaminb"
                            >
                              <Circle
                                score={
                                  navData.vitaBScore ? navData.vitaBScore : 0
                                }
                              />
                              <span
                                class="flex-1 ml-0 text-left whitespace-nowrap"
                                sidebar-toggle-item
                              >
                                Vitamin B
                              </span>
                              <svg
                                sidebar-toggle-item
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </button>
                          </Link>
                          <ul id="vitaminb" class=" py-2 space-y-2">
                            <li>
                              <Link
                                to="/vitaminb3"
                                class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                              >
                                <Circle
                                  score={
                                    navData.vitaB3Score
                                      ? navData.vitaB3Score
                                      : 0
                                  }
                                />{" "}
                                Vitamin B3
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/vitaminb9"
                                class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                              >
                                <Circle
                                  score={
                                    navData.vitaB9Score
                                      ? navData.vitaB9Score
                                      : 0
                                  }
                                />{" "}
                                Vitamin B9
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/vitaminb12"
                                class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                              >
                                <Circle
                                  score={
                                    navData.vitaB12Score
                                      ? navData.vitaB12Score
                                      : 0
                                  }
                                />{" "}
                                Vitamin B12
                              </Link>
                            </li>
                          </ul>
                        </li>

                        {/* End Vitamin B */}
                        <li>
                          <Link
                            to="/vitaminc"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.vitaCScore ? navData.vitaCScore : 0
                              }
                            />{" "}
                            Vitamin C
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="vitamind"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.vitaDScore ? navData.vitaDScore : 0
                              }
                            />{" "}
                            Vitamin D
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="vitamine"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.vitaEScore ? navData.vitaEScore : 0
                              }
                            />{" "}
                            Vitamin E
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* end innter */}
                    {/* Start inner Minerals */}
                    <li className="ml-3">
                      <Link to="/mineralsdash">
                        <button
                          type="button"
                          class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100  "
                          aria-controls="dropdown-minerals"
                          data-collapse-toggle="dropdown-minerals"
                        >
                          <Circle
                            score={
                              navData.mineralScore ? navData.mineralScore : 0
                            }
                          />
                          <span
                            class="flex-1 ml-0 text-left whitespace-nowrap"
                            sidebar-toggle-item
                          >
                            Minerals
                          </span>
                          <svg
                            sidebar-toggle-item
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                      <ul id="dropdown-minerals" class=" py-2 space-y-2">
                        <li>
                          <Link
                            to="/magnesium"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={navData.mgScore ? navData.mgScore : 0}
                            />{" "}
                            Magnesium
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/calcium"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={navData.caScore ? navData.caScore : 0}
                            />{" "}
                            Calcium
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/iron"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={navData.feScore ? navData.feScore : 0}
                            />
                            Iron
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/phosphorous"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={navData.phScore ? navData.phScore : 0}
                            />
                            Phosphorous
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* end innter minerals */}
                    {/* Start inner Nutrients */}
                    <li className="ml-3">
                      <Link to={"/nutrientsdash"}>
                        <button
                          type="button"
                          class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100  "
                          aria-controls="dropdown-nutriants"
                          data-collapse-toggle="dropdown-nutriants"
                        >
                          <Circle
                            score={
                              navData.nutrientScore ? navData.nutrientScore : 0
                            }
                          />
                          <span
                            class="flex-1 ml-0 text-left whitespace-nowrap"
                            sidebar-toggle-item
                          >
                            Nutrients
                          </span>
                          <svg
                            sidebar-toggle-item
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                      <ul id="dropdown-nutriants" class=" py-2 space-y-2">
                        <li>
                          <Link
                            to="/probiotics"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.probioticsScore
                                  ? navData.probioticsScore
                                  : 0
                              }
                            />
                            Probiotics
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/ltheanine"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.lTheanineScore
                                  ? navData.lTheanineScore
                                  : 0
                              }
                            />
                            L-Theanine
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/policosanol"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.policoSanolScore
                                  ? navData.policoSanolScore
                                  : 0
                              }
                            />
                            Policosanol
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/omega3"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.omega3Score ? navData.omega3Score : 0
                              }
                            />
                            Omega 3
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/redyeastrice"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                          >
                            <Circle
                              score={
                                navData.redYeastRice ? navData.redYeastRice : 0
                              }
                            />
                            Red Yeast Rice
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/* end innter minerals */}
                  </ul>
                </li>
                {/* Start of Fitness */}
                <li className="ml-3">
                  <Link to={"/fitnessdash"}>
                    <button
                      type="button"
                      class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100 hover:text-black   "
                      aria-controls="dropdown-fitness"
                      data-collapse-toggle="dropdown-fitness"
                    >
                      {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                      <Circle
                        score={navData.fitnessScore ? navData.fitnessScore : 0}
                      />{" "}
                      <span
                        class="flex-1 ml-0 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        Fitness
                      </span>
                      <svg
                        sidebar-toggle-item
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                  <ul id="dropdown-fitness" class=" py-2 space-y-2">
                    <li>
                      <Link
                        to="/stress"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-black   "
                      >
                        <Circle
                          score={navData.stressScore ? navData.stressScore : 0}
                        />
                        Stress
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/fatigue"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={
                            navData.fatigueScore ? navData.fatigueScore : 0
                          }
                        />{" "}
                        Fatigue
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/bonehealth"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={navData.boneScore ? navData.boneScore : 0}
                        />{" "}
                        Bone Health{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/immunity"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={navData.immuneScore ? navData.immuneScore : 0}
                        />{" "}
                        Immune Function{" "}
                      </Link>
                    </li>
                    <li className="ml-9">
                      <Link to="/metabolismdash">
                        <button
                          type="button"
                          class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white  "
                          aria-controls="dropdown-metabolism"
                          data-collapse-toggle="dropdown-metabolism"
                        >
                          {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                          <Circle
                            score={
                              navData.metabolismScore
                                ? navData.metabolismScore
                                : 0
                            }
                          />{" "}
                          <span
                            class="flex-1 ml-0 text-left whitespace-nowrap"
                            sidebar-toggle-item
                          >
                            Metabolism
                          </span>
                          <svg
                            sidebar-toggle-item
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                      <ul id="dropdown-metabolism" class=" py-2 space-y-2">
                        <li>
                          <Link
                            to="/glucosemetabolism"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                          >
                            <Circle
                              score={
                                navData.glucoseMetabolismScore
                                  ? navData.glucoseMetabolismScore
                                  : 0
                              }
                            />
                            Glucose
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/cholesterolmetabolism"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                          >
                            <Circle
                              score={
                                navData.cholMetabolismScore
                                  ? navData.cholMetabolismScore
                                  : 0
                              }
                            />
                            Cholesterol
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/o2metabolism"
                            class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                          >
                            <Circle
                              score={
                                navData.o2MetabolismScore
                                  ? navData.o2MetabolismScore
                                  : 0
                              }
                            />
                            Oxygen
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                {/* End of fitness */}
                {/* Start of Eastern Medicine */}
                <li className="ml-3">
                  <Link to="/easternmedicine">
                    <button
                      type="button"
                      class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white  "
                      aria-controls="dropdown-medicine"
                      data-collapse-toggle="dropdown-medicine"
                    >
                      {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                      <Circle
                        score={
                          navData.easternMedicineScore
                            ? navData.easternMedicineScore
                            : 0
                        }
                      />{" "}
                      <span
                        class="flex-1 ml-0 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        Eastern Medicine
                      </span>
                      <svg
                        sidebar-toggle-item
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </Link>
                  <ul id="dropdown-medicine" class=" py-2 space-y-2">
                    <li>
                      <Link
                        to="/yinyang"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={navData.yangScore ? navData.yangScore : 0}
                        />{" "}
                        Ying-Yang
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sensitivity"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={
                            navData.sensitivityScore
                              ? navData.sensitivityScore
                              : 0
                          }
                        />
                        Sensitivity
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/circulation"
                        class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white  "
                      >
                        <Circle
                          score={
                            navData.circulationScore
                              ? navData.circulationScore
                              : 0
                          }
                        />
                        Circulation
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* End of Eastern Medicine */}
                {/* end 1 */}
              </ul>
            </li>

            {/* End here */}

            {/* End of wellnes */}

            {/* Start of Skin health */}
            {/* score={navData.skinHealthScore ? navData.skinHealthScore : 0} */}
            <li className="bg-[#F3CD00]">
              <Link to={"/skindash"}>
                <button
                  type="button"
                  class=" ml-3 flex items-center w-full p-2 text-base font-normal  text-white transition duration-75 rounded-lg group     "
                  aria-controls="dropdown-example1"
                  data-collapse-toggle="dropdown-example1"
                >
                  {/* <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover: text-white    " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg> */}
                  <Circle
                    score={
                      navData.skinHealthScore ? navData.skinHealthScore : 0
                    }
                  />{" "}
                  <span
                    class="flex-1 ml-0 text-left whitespace-nowrap"
                    sidebar-toggle-item
                  >
                    Skin Health
                    <div className="flex flex-row">
                      <div></div>
                    </div>
                  </span>
                  <svg
                    sidebar-toggle-item
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </Link>
              <ul id="dropdown-example12" class=" py-2 space-y-2">
                {/* <li>
                  <a
                    href="/skin-health-dashboard"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    Dashboard
                  </a>
                </li> */}
                <li className="flex flex-row">
                  <Link
                    to="/cleansers"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={navData.cleanserScore ? navData.cleanserScore : 0}
                    />{" "}
                    Cleansers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/moisturizers"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={
                        navData.moisturizerScore ? navData.moisturizerScore : 0
                      }
                    />{" "}
                    Moisturizers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sunscreen"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={
                        navData.sunScreenScore ? navData.sunScreenScore : 0
                      }
                    />{" "}
                    Sunscreen
                  </Link>
                </li>
                <li>
                  <Link
                    to="/exfoliants"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={
                        navData.exfoliantsScore ? navData.exfoliantsScore : 0
                      }
                    />{" "}
                    Exfoliants
                  </Link>
                </li>
                <li>
                  <Link
                    to="/anti-aging"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={
                        navData.antiAgingScore ? navData.antiAgingScore : 0
                      }
                    />
                    Antiaging
                  </Link>
                </li>
                <li>
                  <Link
                    to="/brightening"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={
                        navData.brighteningScore ? navData.brighteningScore : 0
                      }
                    />
                    Brightening
                  </Link>
                </li>
                <li>
                  <Link
                    to="/acne-fighting"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle score={navData.acneScore ? navData.acneScore : 0} />{" "}
                    Acnefighting
                  </Link>
                </li>
                <li>
                  <Link
                    to="/soothingsensitiveskin"
                    class="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100  "
                  >
                    <Circle
                      score={navData.soothingScore ? navData.soothingScore : 0}
                    />{" "}
                    Soothing Sensitive Skin
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Nav;
