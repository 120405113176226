import Layout from "./layout";
import { React, useEffect, useRef, useState } from "react";
import inst_video_1 from "../../assets/video/inst_video.webm";
import inst_video_2 from "../../assets/video/inst_video.mp4";
// import inst_video_2 from "../video/inst_video.mp4";
import "../../assets/css/scan.css";
import { useNavigate } from "react-router-dom";
import { updateVerifyScanRecord } from "../firebase/scans";
import Vitals from "./vitals";
import LeftSection from "../common/LeftSection";
import Tooltip from "../common/Tooltip";
import Faq from "./faq/faq";

const Scan = () => {
  const [hideVideo, sethideVideo] = useState(false);
  const [hideSkip, sethideSkip] = useState(false);
  const vidRef = useRef(null);
  const scanVidRef = useRef(null);
  const [fpsStarted, setStartedFps] = useState(false);
  const [hideReportBtn, setHideReportBtn] = useState(true);
  const iOS =
    !!window.navigator.platform &&
    /iPad|iPhone|iPod/.test(window.navigator.platform);
  const navigate = useNavigate();
  const handleStopVideo = () => {
    vidRef.current.pause();
  };
  const handleStartedFps = () => {
    setStartedFps(true);
  };
  const handleReportBtnState = () => {
    setHideReportBtn(false);
  };
  // useEffect(()=>{
  //   if(vidRef.current){
  //     vidRef.current.autoPlay = true;
  //     vidRef.current.loop = true;
  //     vidRef.current.controls = true;
  //     vidRef.current.playsInline = true;
  //     vidRef.current.muted = true;
  //   }
  // },[vidRef])
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    if (canvas) {
      const pageLoadedEvent = new Event("page_loaded");
      document.dispatchEvent(pageLoadedEvent);
    }
  }, []);
  useEffect(() => {
    if (scanVidRef.current) {
      scanVidRef.current.playsInline = true;
      scanVidRef.current.muted = true;
    }
  }, [scanVidRef]);
  const handleVerifyResultClick = async () => {
    // add a loader here
    let respStatus = await updateVerifyScanRecord();
    if (respStatus) {
      // set loader to false
      // navigate("/dashboard");
      // window.location.href="/dashboard";
      window.location.href = "/report";
    } else {
      // console.log("Something happened", respStatus);
    }
  };
  return (
    <div className="flex w-full flex-row">
      {/* <SubModal />
      <Nav /> */}
      {/* <Faq /> */}
      <div className="mx-auto version mt-5">
        <div className="flex flex-row justify-between mt-3 w-full mb-2">
          <div className="md:w-1/2 w-3/4 flex flex-row ">
            <div id="checker" className="text-blue-500 md:text-2xl font-black grow" style={{ whiteSpace: 'nowrap' }}>
              Modules Loading
            </div>
            <div>

            <Tooltip
              text=' The scanning modules will take some time to load in the browser.
              Please wait until "Modules Ready" is shown.'
            />
            </div>
          </div>
          <div class="group flex relative w-full"></div>
          <div></div>
        </div>
        <div className="flex flex-row w-full mb-3">
          <div className="flex grow  flex-col">
            <p className="version">version-3.0.221205</p>
            <p className="fps">
              FPS : <span id="fps_msg"></span>
            </p>
          </div>
          <div className="flex justify-end my-auto">
            {/* <div class="group flex relative w-full text-end justify-end  align-text-bottom my-auto">
              <span class=" text-white">
                <i
                  class="fa fa-info-circle text-blue-800 w-10"
                  aria-hidden="true"
                ></i>
              </span>
              <div
                class="group-hover:opacity-100 transition-opacity bg-blue-400 px-1 w-full  text-gray-100 rounded-md absolute left-1/2 
              -translate-x-1/2 translate-y-1/2 opacity-0  mx-auto"
              >
                The scanning modules will take some time to load in the browser.
                Please wait until "Modules Ready" is shown.
              </div>
            </div> */}
            <Tooltip
              text='This area will show the tutorial video while loading the modules. You can click "Skip video" to enter the scanning mode. By clicking the "Start scan" button at the lower-left, you can start a scan. Keep your facial region visible with the green frame, which means good facial imaging quality.
'
            />
          </div>
        </div>
        <div className="webcam__container">
          <div className="skip__container">
            <div id="skip__indication" hidden onClick={handleStartedFps}></div>
            {fpsStarted ? (
              <div>
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            ) : (
              <div hidden className="">
                {hideSkip ? (
                  <button
                    id="skip__btn"
                    className=""
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                ) : (
                  <button
                    id="skip__btn"
                    className="skip__btn"
                    onClick={() => {
                      sethideVideo(true);
                      sethideSkip(true);
                      handleStopVideo();
                    }}
                    hidden={hideSkip}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="inst__container">
            <video
              ref={vidRef}
              autoPlay
              loop
              muted
              playsInline
              controls
              hidden={hideVideo}
              className="inst__video"
            >
              {iOS ? (
                <source src={inst_video_2} type="video/mp4"></source>
              ) : (
                <source src={inst_video_1} type="video/webm"></source>
              )}
            </video>
          </div>
          <video
            hidden
            id="video"
            width="640"
            height="480"
            ref={scanVidRef}
          ></video>

          <div className="webcam__video">
            <div className="progress__container w-80 md:w-[600px]">
              <div id="progress__value"></div>
            </div>
            <canvas
              id="canvas"
              className="aspect-video md:w-[640px] md:h-[480px] h-48 "
            ></canvas>
          </div>
          <div className="instruction__box">
            <p className="">
              Please look directly into the camera during scanning
            </p>
          </div>
          <div className="button__container">
            <button id="start_stop_btn" value="1" className="start__button">
              Start Capture
            </button>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="info__container my-14 md:my-0">
            <p className="info__message">
              Message : <span className="info__value" id="prog_dynamic"></span>
            </p>
          </div>
        </div>
        <Vitals />
        <div>
          <div
            id="report__indication"
            hidden
            onClick={handleReportBtnState}
          ></div>
          {hideReportBtn ? null : (
            // <center>
            <div className="flex  flex-row mx-auto w-full justify-center px-auto text-center object-center content-center">
              <div className="flex">
                <button
                  className="z-30 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                  onClick={handleVerifyResultClick}
                  style={{ pointerEvents: "auto", marginBottom: "114px" }}
                  hidden={hideReportBtn}
                >
                  Check Your Report
                </button>
                <Tooltip
                  text='After the scanning, you can click "Start scan" to retry
                  scanning if there is any issue. Only the last scanning will be
                  used. You can check the full report by clicking the "Full
                  report" button.'
                />
              </div>

              {/* <div class="group flex relative w-3/4 ml-3">
               

                <Tooltip
                  text='After the scanning, you can click "Start scan" to retry
                  scanning if there is any issue. Only the last scanning will be
                  used. You can check the full report by clicking the "Full
                  report" button.'
                />
              </div> */}
            </div>
            // </center>
          )}
        </div>
      </div>
      <div className="flex">
        <LeftSection />
      </div>
    </div>
  );
};

export default Scan;
