import React, { useEffect, useState } from 'react';
import MyLineChart from "../dashboard/linechart";
import Layout from "../layout";
import BasicIcon from '../../../assets/wellness/v2/Probiotics.png'
import VitalIcon from '../../../assets/wellness/v2/L-theanine.png'
import RecIcon from '../../../assets/wellness/v2/Policosanol.png'
import OIcon from '../../../assets/wellness/v2/Omega 3.png'
import RIcon from '../../../assets/wellness/v2/Red yeast rice.png'
import bg from '../../../assets/wellness/Vitamin.png'
import MyChart2 from "../dashboard/mychart2";
import { fetchWeeklyData, fetchLatestScore } from '../../firebase/scans';
import Tooltip from '../../common/Tooltip';
import LeftSection from '../../common/LeftSection';
const color='yellow-900'
const NutrientsDash = () => {
    const [schartData,setSChartData]=useState([]);
    const [fchartData,setFChartData]=useState([]);
    const [bchartData,setBChartData]=useState([]);
    const [ichartData,setIChartData]=useState([]);
    const [mchartData,setMChartData]=useState([]);
    const setDefaultChartData = async()=>{
        let sresp = await fetchWeeklyData(39,'Probiotics');
        let fresp = await fetchWeeklyData(40,'L-Theanine');
        let bresp = await fetchWeeklyData(41,'Policosanol');
        let iresp = await fetchWeeklyData(42,'Omega-3');
        let mresp = await fetchWeeklyData(43,'Red Yeast Rice');
        if (bresp[0] !== 0){
            setSChartData(sresp);
            setFChartData(fresp);
            setBChartData(bresp);
            setIChartData(iresp);
            setMChartData(mresp);
        }
    }    
    useEffect(()=>{
        setDefaultChartData();
    },[])
    return (
      // <Layout>

      <div
        className="min-h-screen1 w-full flex flex-col md:flex-row pl-3"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSized: "cover",
          backgroundRepeat: "repeat",
        }}
      >

        <div className='grow md:w-3/4'>

        <div className="flex flex-col md:flex-row  pt-1 w-full">
          <div className="flex flex-row md:my-auto mx-auto md:mx-0 mb-4 pr-12 md:w-1/4">
            <div>
              <img src={BasicIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Probiotics
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of probiotics with the average. Click the right arrow to check the detail.




"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row md:w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={schartData} />
            </div>
            <a
              href="/probiotics"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={VitalIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                L-Theanine
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of L theanine with the average. Click the right arrow to check the detail.




"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={fchartData} />
            </div>
            <a
              href="/ltheanine"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RecIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Policosanol
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of policosanol with the average. Click the right arrow to check the detail.




"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={bchartData} />
            </div>
            <a
              href="/policosanol"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={OIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Omega 3
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Omega 3 with the average. Click the right arrow to check the detail.




"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={ichartData} />
            </div>
            <a
              href="/omega3"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        <div className="flex flex-col md:flex-row pt-1 mb-2">
          <div className="flex flex-row mb-4 md:my-auto pr-12 mx-auto md:mx-0 md:w-1/4">
            <div>
              <img src={RIcon} className="h-12" />
            </div>
            <div className="flex flex-col">
              <div className={` my-auto ml-3 text-${color} font-bold`}>
                Red Yeast Rice
              </div>
              <div>
                <Tooltip
                  text="This shows the 7-day trending of Red Yeast Rice with the average. Click the right arrow to check the detail.




"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row w-3/4">
            <div className="md:w-1/2">
              <MyChart2 data={mchartData} />
            </div>
            <a
              href="/redyeastrice"
              className="text-2xl my-auto hover:bg-gray-300 p-3"
            >
              {">"}
            </a>
          </div>
        </div>
        </div>
        <div className='flex'>
          <LeftSection />
        </div>
        
      </div>

      // </Layout>
    );
}
 
export default NutrientsDash;