import React, { useEffect, useState } from "react";
import Layout from "../layout";
// import bg from "../../../assets/01.jpg";
import bg from "../../../assets/Healthiness/HRBRSpo2.jpg";
import BRICON from "../../../assets/Healthiness/Recomendations.jpg";
import Icon from "../../../assets/Healthiness/Icon Breathing rate.png";
import MyLineChart from "../dashboard/linechart";
import MyChart2 from "../dashboard/mychart2";
import Icon2 from "../../../assets/Healthiness/01.png";
import Icon3 from '../../../assets/Healthiness/fitness.png'
import Icon4 from '../../../assets/Healthiness/supplement.png'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { fetchWeeklyData, fetchLatestScore } from "../../firebase/scans";
import LeftSection from "../../common/LeftSection";
import Tooltip from "../../common/Tooltip";

const BreathingRate = () => {
  const [heartScore, setHeartScore] = useState("");
  const [heartPercent, setHeartPercent] = useState(null);
  const [lifestyle, setLifeStyle] = useState("");
  const [fitness, setFitness] = useState("");
  const [supplement, setSupplement] = useState("");
  const [chartData, setChartData] = useState([]);
  const [scanTime, setScanTime] = useState("");
  const setLatestData = async () => {
    let resp = await fetchLatestScore(4, "RR");
    if (resp.score !== 0) {
      setHeartPercent(resp.scorePercentage);
      setHeartScore(resp.scoreText);
      setLifeStyle(resp.recommend.lifestyle);
      setFitness(resp.recommend.fitness);
      setSupplement(resp.recommend.supplement);
      setScanTime(resp.time);
    } else {
      setHeartPercent(1);
      setHeartScore("");
      setLifeStyle("");
      setSupplement("");
      setFitness("");
      setScanTime("");
    }
  };
  const setDefaultChartData = async () => {
    let resp = await fetchWeeklyData(4, "Breathing Rate");
    if (resp[0] !== 0) {
      setChartData(resp);
    }
  };
  useEffect(() => {
    setLatestData();
    setDefaultChartData();
  }, []);
  return (
    <div
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
      className=" flex flex-col md:flex-row"
    >
      <div className="grow md:w-3/4">
        <div className="flex md:flex-row flex-col">
          <div className="rounded-3xl  bg-white flex flex-col m-3 px-7 shadow-lg h-64 my-auto">
            <div className="mx-2 flex flex-col">
              <div>
                <img src={Icon} className="h-8 mx-auto mt-4" />
              </div>
              <div className="mx-auto font-bold text-pink-500">
                Breathing Rate
              </div>
            </div>
            <div style={{ width: 100, height: 100 }} className="mx-auto">
              <CircularProgressbar value={heartPercent} text={heartScore} />
            </div>
            <div className="mx-3  text-white bottom-0  mt-1 flex flex-row ">
              <div className="mx-auto w-full bg-pink-500 rounded-lg">
                <div className="mx-auto">
                  <center>{scanTime}</center>
                </div>
              </div>
              <Tooltip
                text="The score ring shows the ratio of the current score compared to the excellent score. The status in the score ring shows current status, such as Good, Fair, and Subpar. The last scanning date and time are shown at the bottom.
"
              />
            </div>
          </div>
          <div className="md:w-3/4">
            <div className="mt-3 m:w-1/2">
              <MyChart2 data={chartData} />
              <Tooltip
                text="The chart shows the 7-days trending with an average line. The days of the week is shown on upper-right of the chart.
"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row mb-1">
          <Tooltip
            text="Recommendation section shows the suggestion for daily style, fitness, and supplement that you can take to improve your current status.
"
          />

          <div className="flex flex-col  md:mx0 px-0 mt-3 border rounded-lg bg-white">
            <div className="bg-pink-500 text-white font-bold rounded-t-lg p-2 ">
              <center className="mx-auto">Recommendations</center>
            </div>
            <div className="flex md:flex-row border-b">
              <div className="px-10 py-3 md:w-1/5 w-full">
                <img src={Icon2} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5">{lifestyle}</div>
            </div>
            <div className="flex md:flex-row border-b">
              <div className="px-10 py-3 md:w-1/5 w-full">
                <img src={Icon3} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5">{fitness}</div>
            </div>
            <div className="flex md:flex-row border-b">
              <div className="px-10 py-3 md:w-1/5 w-full">
                <img src={Icon4} className="h-20" />
              </div>
              <div className="my-auto md:w-4/5">{supplement}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <LeftSection />
      </div>
    </div>
  );
};

export default BreathingRate;
