import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import bg from "../../../assets/01.jpg";
import Logo from "../../../assets/images/VitalPic.png";
import {
  fetchUserInfo,
  logOutWithFirebase,
  updateUserInfo,
} from "../../firebase/auth";
import SubModal from "./submodal";

const Profile = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [subText, setSubText] = useState("");
  const [setGoal, updateGoal] = useState(false);
  const [fullName, setFullName] = useState("");
  const [heightRadioValue, setHeightRadioValue] = useState("imperial");
  const [isHeightImperial, setIsHeightImperial] = useState(true);
  const [heightValueFt, setHeightValueFt] = useState();
  const [heightValueInch, setHeightValueInch] = useState();
  const [heightValueCm, setHeightValueCm] = useState();

  const [genderValue, setGenderValue] = useState();

  const [weightValue, setWeightValue] = useState();
  const [weightOption, setWeightOption] = useState();

  const [birthYear, setBirthYear] = useState();

  const handleChangeWeightOption = (e) => {
    if (e.target.value === "0") {
      // KG
      setWeightValue(Number((weightValue * 0.453592).toFixed(0)));
    } else {
      setWeightValue(Number((weightValue * 2.204).toFixed(2)));
    }
  };

  const handleChangeHeightRadio = (event) => {
    setHeightRadioValue(event.target.value);
    if (event.target.value == "imperial") {
      setIsHeightImperial(true);
      let heightResp = convertCmToFeetAndInches(heightValueCm);
      setHeightValueFt(heightResp[0]);
      setHeightValueInch(heightResp[1]);
    } else {
      setIsHeightImperial(false);
      setHeightValueCm(
        convertFeetAndInchesToCm(heightValueFt, heightValueInch)
      );
    }
  };
  const convertFeetAndInchesToCm = (feet, inches) => {
    let totalInches = feet * 12 + parseInt(inches);
    return Number((totalInches * 2.54).toFixed(1));
  };
  const convertCmToFeetAndInches = (input) => {
    let totalInches = input / 2.54;
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches % 12);
    return [feet, inches];
  };

  const convertInchesToFeetAndInches = (input) => {
    // input = 67 inch , output= 5ft 7 inch
    let feet = Math.floor(input / 12);
    let inches = Math.round(input % 12);
    return [feet, inches];
  };
  const convertFeetAndInchesToInches = (feet, inches) => {
    return Number((feet * 12 + parseInt(inches)).toFixed(1));
  };
  const convertCmToInches = (input) => {
    return Number((input / 2.54).toFixed(1));
  };
  const main = async () => {
    const res = await fetchUserInfo();
    setEmail(res.email);
    setFirstName(res.firstName);
    setLastName(res.lastName);
    setBirthYear(res.birthYear);
    setGenderValue(res.gender);
    setWeightValue(res.weight); // lbs
    setWeightOption("1");
    // setHeightValue(res.height) // INCH
    let heightBreakdown = convertInchesToFeetAndInches(res.height);
    setHeightValueFt(heightBreakdown[0]);
    setHeightValueInch(heightBreakdown[1]);
    setIsHeightImperial(true);

    setFullName(`${res.firstName} ${res.lastName}`);
    if (res.subscription) {
      setSubText("Subscription is Active");
    } 
  };
  useEffect(() => {
    main();
    updateGoal(false);
  }, []);
  const handleClick = async () => {
    let heightValue = 0;
    if (isHeightImperial) {
      heightValue = convertFeetAndInchesToInches(
        heightValueFt,
        heightValueInch
      );
    } else {
      heightValue = convertCmToInches(heightValueCm);
    }

    if (
      weightValue === null ||
      heightValue === null ||
      genderValue === null ||
      weightValue === 0 ||
      heightValue === 0 
    ) {
      updateGoal(false);
    }
    let weightInLbs;
    
    if (weightOption === "0") {
      weightInLbs = Number((weightValue * 2.20462).toFixed(0));
    } else {
      weightInLbs = Number(weightValue.toFixed(2));
    }
    
    const res = await updateUserInfo(
      setGoal,
      heightValue,
      weightInLbs,
      Number(genderValue)
    );
    if (true) {
      main();
    }
  };
  const handleLogOut = async () => {
    await logOutWithFirebase();
    localStorage.clear();
    // navigate("/");
    window.location.href = "/";
  };
  return (
    <div
      className=" flex min-h-screen flex-col"
      style={{ backgroundImage: `url(${bg})`, backgroundSize: "cover" }}
    >
      <nav class="fixed top-0 z-50 w-full bg-inherit mb-5 p-3 ">
        <div class="px-3 py-0 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              >
                <span class="sr-only">Open sidebar</span>
                <svg
                  class="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="#" class="flex ml-2 md:mr-24">
                {/* <img
                  src={Logo}
                  class="h-16 mr-3"
                  alt="FlowBite Logo"
                /> */}
                {/* <span class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap ">
                  HMS
                </span> */}
              </a>
            </div>
            <div class="flex items-center">
              <div class="flex items-center ml-3">
                <div>
                  <button
                    type="button"
                    class="flex text-sm  bg-inherit rounded-full focus:ring-4 focus:ring-gray-300 "
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span class="sr-only">Open user menu</span>
                    {/* <img
                      class="w-8 h-8 rounded-full"
                      src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                      alt="user photo"
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span className="my-auto bg-inherit text-white mx-1 font-black"></span>
                  </button>
                </div>
                <div
                  class="z-50 hidden my-0 text-base list-none bg-white divide-y divide-gray-100 rounded shadow "
                  id="dropdown-user"
                >
                  <div class="px-4 py-0" role="none">
                    <p class="text-sm text-gray-900 " role="none">
                      {fullName}
                    </p>
                    <p
                      class="text-sm font-medium text-gray-900 truncate "
                      role="none"
                    >
                      {email}
                    </p>
                  </div>
                  <ul class="py-1" role="none">
                    <li>
                      <a
                        href="/dashboard"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Dashboard
                      </a>
                    </li>
                    <li>
                      <a
                        href="/profile"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Profile
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="/subscription"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Subscription
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href="#"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Settings
                      </a>
                    </li> */}

                    <li>
                      <a
                        // href="/"
                        onClick={handleLogOut}
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  "
                        role="menuitem"
                      >
                        Sign out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* <SubModal /> */}

      <div className=" mx-auto">
        <img src={Logo} className="md:h-36 h-24 mx-auto" />
      </div>
      <div className="flex flex-col md:flex-row w-full">
        {/* Upper div */}
        <div className="flex md:w-1/2 w-full">
          {/* Details */}
          <div className="mx-auto md:w-1/2 w-full">
            {error ? (
              <>
                <div
                  class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50  "
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    class="flex-shrink-0 inline w-5 h-5 mr-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="sr-only">Info</span>
                  <div>{error}!</div>
                </div>
              </>
            ) : null}

            {/* Goal: {setGoal.toString()} name: {changeName.toString()}
        password: {setPassword.toString()} */}
            <form>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_email"
                  id="floating_email"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={firstName}
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium absolute text-sm text-white  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  First Name
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_password"
                  id="floating_password"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={lastName}
                />
                <label
                  for="floating_password"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last Name
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="repeat_password"
                  id="floating_repeat_password"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  value={email}
                />
                <label
                  for="floating_repeat_password"
                  class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group flex flex-row  justify-between">
                <div></div>
              </div>
            </form>
          </div>
        </div>
        <div>
          {/* Other details */}

          <div className="flex flex-col text-white">
            <div class="mb-2 ">
              <label
                for="large-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white "
              >
                Height
              </label>
              <div className="flex flex-row md:space-x-4 mb-2 ">
                <div>
                  <input
                    type="radio"
                    id="imperial"
                    name="measurement"
                    value="imperial"
                    className="mr-2"
                    checked={heightRadioValue === "imperial"}
                    onChange={handleChangeHeightRadio}
                  />
                  <label htmlFor="imperial ml-3">Imperial</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="metric"
                    name="measurement"
                    value="metric"
                    className="mr-2"
                    checked={heightRadioValue === "metric"}
                    onChange={handleChangeHeightRadio}
                  />
                  <label htmlFor="metric">Metric</label>
                </div>
              </div>
              {isHeightImperial ? (
                <div className="flex flex-row space-x-0 w-3/4 text-white">
                  <div className="flex flex-row space-x-2 ">
                    {/* <label htmlFor="ft-input">Ft</label> */}
                    <input
                      id="ft-input"
                      type="number"
                      placeholder="Ft"
                      value={heightValueFt}
                      onChange={(e) => {
                        setHeightValueFt(e.target.value);
                        updateGoal(true);
                      }}
                      class="block w-2/3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                    />
                    <div className="my-auto">
                      <label htmlFor="inch-input">Ft</label>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row space-x-2 ">
                      <input
                        id="inch-input"
                        type="number"
                        placeholder="Inch"
                        value={heightValueInch}
                        onChange={(e) => {
                          setHeightValueInch(e.target.value);
                          updateGoal(true);
                        }}
                        class="block w-2/3 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                      />
                      <div className="my-auto ml-0">
                        <label htmlFor="inch-input">Inch</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row space-x-2">
                    
                    <input
                      type="number"
                      placeholder="Cm"
                      value={heightValueCm}
                      onChange={(e) => {
                        setHeightValueCm(e.target.value);
                        updateGoal(true);
                      }}
                      id="cm-input"
                      class="block w-1/4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                    />
                    <div className="my-auto ml-0">
                     <label htmlFor="cm-input">Cm</label>
                    </div>

                  <div>
                    {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
                    {/* <select value={heightOption} onChange={(e)=>{setHeightOption(e.target.value);handleChangeHeightOption(e);}} id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                        <option value={0}>Ft</option>
                        <option value={1}>Inch</option>
                      </select> */}
                  </div>
                </div>
              )}
            </div>
            <div class="mb-1">
              <label
                for="large-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Weight
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  <input
                    type="number"
                    value={weightValue}
                    onChange={(e) => {
                      setWeightValue(e.target.value);
                      updateGoal(true);
                    }}
                    id="large-input"
                    class="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                  />
                </div>
                <div>
                  {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
                  <select
                    value={weightOption}
                    onChange={(e) => {
                      setWeightOption(e.target.value);
                      handleChangeWeightOption(e);
                    }}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option value={0}>KG</option>
                    <option value={1}>Lbs</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <label
                for="large-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Physical Gender
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  {/* <input type="text" id="large-input" class="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " /> */}
                </div>
                <div>
                  {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
                  <select
                    value={genderValue}
                    onChange={(e) => {
                      setGenderValue(e.target.value);
                      updateGoal(true);
                    }}
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option value={0}>M</option>
                    <option value={1}>F</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="mb-1">
              <label
                for="large-input"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Year of birth
              </label>
              <div className="flex flex-row space-x-1">
                <div>
                  <input
                    type="number"
                    value={birthYear}
                    class="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 "
                  />
                </div>
                <div>
                  {/* <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select an option</label> */}
                  {/* <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
                    <option selected>M</option>
                    <option value="US">F</option>
                    
                    </select> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="mx-auto font-bold text-white py-1 text-lg">{subText}</div>
      <div className="mx-auto">
        <button
          type="submit"
          onClick={() => handleClick()}
          class="text-white md:px-20 px-5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default Profile;
