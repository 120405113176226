import { Chart } from 'chart.js';
import React, { useRef, useEffect } from 'react';

const MyChart = (props) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    const DATA_COUNT = 7;
    const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
    const labels = ['','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    const data = {
      labels: labels,
      datasets: [
        {
          label: `${props.data[3]} Score`,
          // label: `Vital Score`,
          type:'line',
          // data: [
          //   {x:"Monday",y:1},
          //   {x:"Tuesday",y:1},
          //   {x:"Wednesday",y:3},
          // ],
          // data:[{x:"Monday",y:0.5},{x:"Wednesday",y:2},{x:"Friday",y:1.5}],
          data:props.data[1],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgb(255, 99, 132)",
          yAxisID:'y',
        },
        {
          label: 'Average Weekly Rating',
          type:'line',
          // data: [
          //   {x:"Monday",y:1},
          //   {x:"Tuesday",y:1},
          //   {x:"Wednesday",y:3},
          // ],
          data:props.data[0],
          // data:[{x:"Sunday",y:2},{x:"Monday",y:2},{x:"Tuesday",y:2},{x:"Wednesday",y:2},{x:"Thursday",y:2},{x:"Friday",y:2},{x:"Saturday",y:2}],
          borderColor: props.data[2],
          // borderColor: "rgb(213, 219, 33)",
          backgroundColor: "rgb(255, 99, 132)",
          yAxisID:'y5',
          pointRadius: 0,
        },
      ]
    };

    const chartConfig = {
      type: 'line',
      data: data,
      options: {
        tooltip:{
          callbacks:{
            label:function(tooltipItem,data){
              return tooltipItem.yLabel
            }
          }
        },
        responsive: true,
        plugins: {
          legend:{
            display:false,
          },
          title: {
            display: false,
            text: 'Stacked scales',
          },
        },
        scales: {
          x:{
            grid:{
              display:false
            }
          },
          y1: {
            min:0,
            type: 'category',
            labels: ['SUBPAR'],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              width:10,
              color: "rgb(219, 61, 33)"
            },
            ticks:{
              stepSize:1,
            }
            // grid:{
            //   display:false
            // }
          },
          y2: {
            min:0,
            type: 'category',
            labels: ['FAIR'],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              width:10,
              color: "rgb(213, 219, 33)"
            },
            grid:{
              display:false
            },
            ticks:{
              stepSize:1,
            }
          },
          y3: {
            min:0,
            type: 'category',
            labels: ['GOOD'],
            offset: true,
            position: 'left',
            stack: 'demo',
            stackWeight: 1,
            border: {
              color: "rgb(61,219,33)",
              width:10,
            },
            ticks:{
              stepSize:1,
            }
            // grid:{
            //   display:false
            // }
          },
          y:{
            min:0,
            max:4,
            display:false,
            beginAtZero: true,
            offset:true,
            position: 'left',
            stackWeight: 1,
            grid:{
              display:false
            },
            ticks:{
              // beginAtZero: false,
              stepSize:1,
              // drawTicks:false,
            }
          },
          y5:{
            min:0,
            max:4,
            display:false,
            // beginAtZero: true,
            offset:true,
            position: 'left',
            stackWeight: 1,
            grid:{
              display:false
            },
            ticks:{
              // beginAtZero: false,
              stepSize:1,
              // drawTicks:false,
            }
          },
          
        }
      }
    };

    const myChart = new Chart(chartContainer.current, chartConfig);

    return () => {
      myChart.destroy();
    };
  }, [props]);

  return (
    <div>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default MyChart;
